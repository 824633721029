import React, { Fragment } from 'react';

const TermsEN = () => {
  return (
    <Fragment>
      <p>
        of the business corporation speedlo.cz s.r.o., ID No.: 08750866 with its
        registered office at Hlaváčova 207, Pardubice 530 02, (hereinafter
        referred to the “speedlo”) regarding the access to and use of the
        website <a href="https://speedlo.cz">www.speedlo.cz</a> (hereinafter
        referred to the “Terms”).
      </p>
      <h3>I. Introductory Provisions</h3>
      <ol>
        <li>
          speedlo operates and manages a web portal combining restaurant
          operations delivering food on the website{" "}
          <a href="https://speedlo.cz">www.speedlo.cz</a> ( hereinafter referred
          to the "speedlo Portal"). speedlo leases the speedlo Portal and the
          related services to other entrepreneurs (hereinafter referred to the
          “Interested Party”) and develops activities to enable the Interested
          Party to enter into contracts with third parties (hereinafter referred
          to the “Customer”) for the use of the Interested Party’s services
          and/or goods hereinafter referred to the "Mediated Contract").
        </li>
        <li>
          These Terms govern the rights and obligations of individuals using the
          speedlo Portal (hereinafter referred to the “User”) when accessing the
          speedlo Portal, and the further use of the speedlo Portal, as well as
          other related legal relationships.
        </li>
        <li>
          By accessing the speedlo Portal, the User confirms that he/she has
          read the current wording of the Terms and undertakes to follow them.
        </li>
        <li>
          speedlo may unilaterally amend or supplement the wording of the Terms.
          speedlo informs the User about the Terms amendment on the speedlo
          Portal, or in another suitable way so that the User can get acquainted
          with the current wording of the Terms without any difficulties. The
          Terms become effective as of the moment of their publication on the
          speedlo Portal. This provision does not affect the rights and
          obligations arising during the effectiveness period of the previous
          version of the Terms.
        </li>
      </ol>
      <h3>II. Using the speedlo Portal</h3>
      <ol>
        <li>
          Access to and use of the speedlo Portal is free of charge. However,
          the User bears the costs incurred by him/her in connection with the
          access to and use of the speedlo Portal (i.e. the costs of internet
          connection, etc.).
        </li>
        <li>
          speedlo is not a party to the contractual relations between the
          Customer and the Interested Party. speedlo does not bear any liability
          for the fulfillment of obligations under the Mediated Contract nor
          does it bear any liability for any damage incurred by the User on the
          basis of or in connection with the Mediated Contract.
        </li>
        <li>
          speedlo leases the speedlo Portal to the Interested Parties, and is
          not liable for any defects in the contents or appearance of materials
          published by the Interested Parties on the speedlo Portal. speedlo is
          not liable for the completeness, accuracy, truthfulness of the
          materials published on the speedlo Portal, or for any conduct of
          unfair competition by the Interested Party through the speedlo Portal,
          or for any violation of personal rights or copyright. All prices on
          the speedlo Portal are prices for the purchase of a voucher. speedlo
          is not liable for any damages incurred in connection with the use of
          information published on the speedlo Portal.
        </li>
        <li>
          speedlo does not guarantee uninterrupted access to the speedlo Portal,
          nor the unobjectionability and security of the speedlo Portal. speedlo
          is not liable for any damage caused to the User during access to and
          use of the speedlo.cz Portal, including any damage caused by
          downloading data published on the speedlo.cz Portal, any damage caused
          by interruption of operation, speedlo Portal failure, computer
          viruses, damage due to data loss, lost profit, unauthorized access to
          the User’s transmissions and data.
        </li>
        <li>
          Clicking on certain links on the speedlo Portal may lead to leaving
          the speedlo Portal and redirecting you to third party websites.
        </li>
        <li>
          speedlo reserves the right to restrict or terminate the User’s access
          to the speedlo Portal at any time.
        </li>
        <li>
          The User is liable for any and all damage caused by the User’s
          unauthorized intervention into the speedlo Portal or the system that
          carries out the transfer from the speedlo Portal to third parties.
        </li>
      </ol>
      <h3>III. Copyright</h3>
      <ol>
        <li>speedlo is the sole owner of the speedlo Portal.</li>
        <li>
          The speedlo Portal is an author’s work in the meaning of Act No.
          121/2000 Coll., the Copyright Act, as amended. speedlo exercises all
          property rights related to the speedlo Portal.
        </li>
        <li>
          The content of the speedlo Portal may not be stored, modified,
          distributed or otherwise subject to other exercise of property rights
          without the speedlo’s prior consent to such conduct.
        </li>
      </ol>
      <h3>IV. User’s Rights and Obligations</h3>
      <ol>
        <li>
          The User declares and warrants to speedlo that:
          <ol>
            <li>
              he/she is competent to make legal acts, in particular with regard
              to his/her age, or that he/she is represented by his/her legal
              representative,
            </li>
            <li>
              all information provided to speedlo is true, complete, accurate
              and correct,
            </li>
            <li>
              he/she has thoroughly acquainted himself/herself with these Terms
              before starting to use the speedlo Portal, that he/she fully
              understands and agrees with these Terms,
            </li>
            <li>
              he/she will use the speedlo portal and shop on it through no more
              than one user account. In case of a justified suspicion that the
              User has created and uses more than one user account, speedlo has
              the right to block and delete such accounts.
            </li>
          </ol>
        </li>
        <li>
          While using the speedlo Portal the User is obliged to comply with
          legal regulations and he/she is also obliged to always respect the
          rights of speedlo and third parties, especially when handling
          copyrighted works and other objects of intellectual property rights.
          In particular, the User must not:
          <ol>
            <li>use the speedlo Portal in violation of these Terms,</li>
            <li>
              commercially use any part of the speedlo.cz Portal in a manner
              capable of damaging speedlo and/or the Interested Party,
            </li>
            <li>obtain login names and/or passwords of other Users,</li>
            <li>
              misuse, block, modify or otherwise change any part of the speedlo
              Portal, or even attempt to disrupt the speedlo Portal’s stability,
              operation or data,
            </li>
            <li>
              use mechanisms, software or other procedures that could adversely
              affect the speedlo Portal’s operation. The speedlo Portal may be
              used only in the extent that is not to the detriment of the rights
              of other Users or speedlo and which is in accordance with its
              purpose.
            </li>
          </ol>
        </li>
        <li>
          Notwithstanding any other provisions of these Terms, the User
          undertakes that:
          <ol>
            <li>
              he/she will not use the speedlo Portal if its use by the User
              would violate legal regulations,
            </li>
            <li>
              if he/she is under 18 years of age, he/she will not use such
              services of the speedlo.cz Portal, for which the condition for use
              is the minimum age of 18 years.
            </li>
          </ol>
        </li>
        <li>
          Should it be impossible to reliably verify that the User is older than
          18 years of age, the handover of the Interested Party’s services
          and/or goods forming the subject of the Mediated Contract, which are
          the subject to the minimum age of 18 years condition, will not take
          place and the Interested Party will automatically withdraw from the
          Mediated Contract concluded between the Interested Party and the
          Customer.
        </li>
        <li>
          The User acknowledges that for the purposes of the Mediated Contract
          fulfillment, the Interested Party or speedlo will contact him/her via
          e-mail, or using the telephone number provided to speedlo, in
          accordance with the terms and conditions of personal data processing
          published and freely available on the speedlo Portal. The User is
          therefore obliged to expect that before the Interested Party’s
          services and/or goods delivery, he/she will be contacted by the
          delivering person (courier).
        </li>
      </ol>
      <h3>
        V. Price for the Use of the Interested Party’s Services and/or Goods and
        Payment Terms
      </h3>
      <ol>
        <li>
          The User acknowledges that the final price of the purchase order is
          stated after rounding to whole crowns in accordance with the relevant
          legal regulations. A detailed breakdown of the purchase price, incl.
          hallers, is always stated on the delivery note and on the tax document
          issued by the Company.
        </li>
        <li>
          The price for the Interested Party’s services used and/or goods and
          any other costs (e.g. the delivery costs) shall be paid by the User in
          one of the following ways:
          <ol>
            <li>
              a cashless payment card through a secure internet payment gateway
              provided by ČSOB, PayU, or GoPay, which is a part of the speedlo
              Portal;
            </li>
            <li>cash on delivery;</li>
            <li>
              meal vouchers (however, a maximum of 5 pieces per a purchase
              order);
            </li>
            <li>
              in another way according to the speedlo Portal’s current offer.
            </li>
          </ol>
        </li>
        <li>
          The price for the use of the Interested Party’s services and/or goods
          is stated incl. VAT.
        </li>
        <li>
          Payment by cashless payment card: If the User has chosen payment by a
          cashless payment card as the method of payment, he/she shall proceed
          according to the steps communicated to him/her by the relevant
          internet payment gateway. speedlo is not obliged to mediate services
          and/or goods and the Company is not obliged to provide services and/or
          goods under the purchase order to the User before payment of the price
          for the use of the services and/or goods by the User. If the User
          intends to pay for the services and/or goods through the ČSOB payment
          gateway (internet payment gateway) operated by Československá obchodní
          banka, a.s., with registered office in Prague 5, Radlická 333/150,
          Postal code 150 57, ID No.: 00001350, before its use the User is
          obliged to get acquainted with the relevant business terms and
          conditions governing the use of this gateway. The ČSOB payment gateway
          is operated exclusively by Československá obchodní banka, a.s.,
          therefore, speedlo and the Company do not bear any liability for any
          damage caused to the User based on the use of this payment system.
        </li>
        <li>
          Payment in cash upon receipt of services - If the User chooses the
          cash method of payment upon receipt of the service and/or goods, the
          price is payable upon the ordered service and/or goods delivery. The
          price for the service and/or goods is deemed paid at the moment of the
          relevant amount handing over to the delivering person (courier) upon
          receipt of the delivered service and/or goods.
        </li>
        <li>
          When paying using meal vouchers or a meal voucher card, the User
          undertakes to order only services and/or goods not containing
          alcoholic beverages through the speedlo Portal.
        </li>
        <li>
          The User also expressly acknowledges that if he/she chooses the method
          of payment using paper meal vouchers for the completion of a purchase
          order placed through the speedlo Portal, it may happen that the
          Interested Party does not accept the issuer of the User’s paper meal
          vouchers. If this situation occurs, the User is obliged to pay for the
          purchase order in cash.
        </li>
        <li>
          If the Customer gives a tip to the delivering person for the
          Interested Party’s service and/or goods delivery, the tip thus given
          does not represent speedlo’s income.
        </li>
        <li>
          The User also has the possibility to pay the price for the purchase
          order using vouchers issued by speedlo to the User as part of customer
          care.
        </li>
      </ol>
      <h3>VI. Final Provisions</h3>
      <ol>
        <li>
          All meals, dishes, beverages and/or goods offered by the Interested
          Party to Customers through the speedlo Portal may contain allergens.
          For more information about allergens contained in specific meals,
          dishes, beverages and/or other goods, the Customers may contact
          customer support at{" "}
          <a href="mailto:info@speedlo.cz">info&#064;speedlo.cz</a>. The
          customer support will provide information on the exact contents of the
          respective dish. This information is available to Customers at the
          time of delivery, from the relevant courier.
        </li>
        <li>
          In accordance with the provisions of Sec. 1829 of the Civil Code, the
          Customer has the right to withdraw from the Mediated Contract within
          14 days of the goods and/or services receipt, even in respect of an
          individual item of the goods and/or services. In the event of
          withdrawal from the Mediated Contract, the Customer may hand over the
          goods and/or services immediately to the person who handed them over
          on behalf of speedlo, and if this is not possible, the Customer is
          obliged to notify speedlo about the withdrawal from the Contract by
          phone or via e-mail at&nbsp;
          <a href="mailto:info@speedlo.cz">info&#064;speedlo.cz</a> and send the
          goods and/or services, at his/her own expense, to the Interested
          Party’s address (generally to the address of its operating premises or
          registered office or place of business and/or using the contact
          details provided by the Interested Party in its profile on the speedlo
          Portal). For the avoidance of any doubt, speedlo declares, in
          accordance with the relevant provisions of the Civil Code, that it is
          not possible to withdraw from a Mediated Contract that relates to
          perishable goods and/or services (e.g. fruit, vegetables or foodstuffs
          of animal origin) or goods and/or services that have been irreversibly
          mixed with other goods and/or services after delivery. An exception is
          fruit and vegetables that have a different country of origin on the
          packaging than the one indicated on the speedlo Portal. Furthermore,
          the Customer may not withdraw from the Mediated Contract if it relates
          to goods and/or services that were delivered in a closed package,
          which the Customer removed from the package and for hygienic reasons
          it is not possible to return them. The Customer also may not withdraw
          from the Mediated Contract for the supply of newspapers, periodicals
          or magazines, as well as for the supply of an audio or video recording
          or a computer program, if he/she has broken their packaging. In the
          event of a valid withdrawal from the Mediated Contract, the price of
          goods and/or services, or a proportional part thereof, will be
          returned within 14 days from the goods and/or services returning, to
          the Customer’s bank account or to the bank account from which the
          Customer paid the price, including the delivery costs, or a
          proportional part thereof.
        </li>
        <li>
          The rights and obligations between the Interested Party and the User
          regarding the rights arising from defective performance are governed
          by the relevant generally binding legal regulations (especially the
          provisions of Sec. 1914 to 1925, Sec. 2099 to 2117 and Sec. 2161 to
          2174 of Act No. 89/2012 Coll., the Civil Code and Act No. 634/1992
          Coll., on Consumer Protection, as amended). The User, in the position
          of a consumer, has the right to assert the rights arising from
          defective performance in connection with the Mediated Contract with
          the Interested Party (generally at the address of its operating
          premises, where the assertion of rights arising from defective
          performance is possible with regard to the range of goods sold, or
          possibly also in the Interested Party’s registered office or place of
          business) and/or using the e-mail address{" "}
          <a href="mailto:info@speedlo.cz">info&#064;speedlo.cz</a> and taking
          into account the fact that the subject of the Mediated Contract is the
          purchase of consumable goods (food and beverages), which are
          perishable goods.
        </li>
        <li>
          The Czech Trade Inspection Authority, with its registered office at
          Štěpánská 567/15, 120 00 Prague 2, ID No.: 000 20 869, website:&nbsp;
          <a href="https://www.coi.cz/" target={"_blank"}>
            https://www.coi.cz
          </a>
          , shall be relevant for out-of-court settlement of any consumer
          disputes.
        </li>
        <li>
          All legal relations arising on the basis of or in connection with the
          speedlo Portal are governed by the laws of the Czech Republic,
          regardless of where the access to and use of the speedlo Portal has
          taken place.
        </li>
        <li>
          Should any of the provisions of these Terms be or become void or
          ineffective, such invalid provisions shall be replaced by a provision,
          the meaning of which will be as close as possible to the invalid
          provision. The invalidity or ineffectiveness of any provision hereof
          shall not affect the validity and effectiveness of the remaining
          provisions.
        </li>
        <li>These Terms become effective on 26 June 2020.</li>
      </ol>
      <p>In Pardubice, on 26 June 2020</p>
    </Fragment>
  );
};

export default TermsEN;
