
export const StringWebalizer = (str) => {

    var nodiac = {
        'á': 'a',
        'č': 'c',
        'ď': 'd',
        'é': 'e',
        'ě': 'e',
        'í': 'i',
        'ň': 'n',
        'ó': 'o',
        'ř': 'r',
        'š': 's',
        'ť': 't',
        'ú': 'u',
        'ů': 'u',
        'ý': 'y',
        'ž': 'z'
    };

    let string = str.toLowerCase();
    let s2 = '';
    for (let i = 0; i < string.length; i++) {
        s2 += (typeof nodiac[string.charAt(i)] != 'undefined' ? nodiac[string.charAt(i)] : string.charAt(i));
    }

    return s2.replace(/[^a-z0-9_]+/g, '-').replace(/^-|-$/g, '');

};
