import "../i18n"

import React, { Component, Fragment } from "react"

import { Translation, withTranslation } from "react-i18next"

import { library } from "@fortawesome/fontawesome-svg-core"
import { fab } from "@fortawesome/free-brands-svg-icons"
import { fas } from "@fortawesome/free-solid-svg-icons"

import IosBanner from "./Components/IosBanner"

library.add(fas, fab)

class App extends Component {
  constructor() {
    super()

    this.state = {
      iosBanner: true,
    }
  }

  componentDidMount() {
    setTimeout(
      function () {
        this.setState({ iosBanner: false })
      }.bind(this),
      10000
    )
  }

  render() {
    return (
      <Translation>
        {(t) => (
          <Fragment>
            {this.props.children}
            {this.state.iosBanner ? <IosBanner /> : null}
          </Fragment>
        )}
      </Translation>
    )
  }
}

export default withTranslation("translation")(App)
