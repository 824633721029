import React, {Fragment} from "react";

const TermsCZ = () => {

    return (
        <Fragment>
            <p>obchodní společnosti speedlo.cz s.r.o., IČO: 08750866 se sídlem Hlaváčova 207,
                Pardubice
                530 02, (dále jen „speedlo“) pro přístup a užití webových stránek <a href="https://speedlo.cz">www.speedlo.cz</a>&nbsp;
                (dále
                jen „Podmínky“).</p>
            <h3>I. Úvodní ustanovení</h3>
            <ol>
                <li>
                    speedlo provozuje a spravuje webový portál slučující restaurační provozy rozvážející
                    jídla na internetové stránce <a href="https://speedlo.cz">www.speedlo.cz</a> (dále jen „Portál speedlo“). speedlo
                    pronajímá Portál speedlo a související služby jiným podnikatelům (dále jen „Zájemce“) a
                    vyvíjí činnost k tomu, aby Zájemce měl možnost prostřednictvím Portálu speedlo uzavírat
                    s třetími osobami (dále jen „Zákazník“) smlouvy na využití služeb a/nebo zboží Zájemce
                    (dále jen „Zprostředkovaná smlouva“).
                </li>
                <li>
                    Podmínky upravují práva a povinnosti osob využívajících Portál speedlo (dále jen
                    „Uživatel“) při vstupu na Portál speedlo, a dalším používání Portálu speedlo, jakož i
                    další související právní vztahy.
                </li>
                <li>
                    Uživatel vstupem na Portál speedlo stvrzuje, že se seznámil s aktuálním zněním Podmínek,
                    a zavazuje se jimi řídit.
                </li>
                <li>
                    speedlo může znění podmínek jednostranně měnit či doplňovat. speedlo informuje Uživatele
                    o změně Podmínek na Portálu speedlo, případně jiným vhodným způsobem tak, aby se s
                    aktuálním zněním podmínek Uživatel mohl bez obtíží seznámit. Podmínky nabývají účinnosti
                    okamžikem jejich zveřejnění na Portálu speedlo. Tímto ustanovením nejsou dotčena práva a
                    povinnosti vzniklá po dobu účinnosti předchozího znění Podmínek.
                </li>
            </ol>
            <h3>II. Použití Portálu speedlo</h3>
            <ol>
                <li>
                    Přístup a používání Portálu speedlo je bezplatné. Uživatel nese však náklady vzniklé mu
                    v souvislosti s realizací přístupu a používání Portálu speedlo (tj. náklady na
                    internetové připojení aj.).
                </li>
                <li>
                    speedlo není účastníkem smluvních vztahů mezi Zákazníkem a Zájemcem. speedlo nenese
                    žádnou odpovědnost za splnění povinností ze Zprostředkované smlouvy ani nenese
                    odpovědnost za případnou škodu vzniklou Uživateli na základě, nebo v souvislosti se
                    Zprostředkovanou smlouvou.
                </li>
                <li>
                    speedlo pronajímá Portál speedlo Zájemcům, a nenese odpovědnost za případnou závadnost
                    obsahu či vzhledu materiálů zveřejňovaných Zájemci na Portálu speedlo. speedlo
                    neodpovídá za úplnost, přesnost, pravdivost materiálů zveřejňovaných na Portálu speedlo,
                    ani za případné nekalé soutěžní jednání Zájemce prostřednictvím Portálu speedlo, nebo
                    porušení osobnostních nebo autorských práv. Všechny ceny na portálu speedlo jsou ceny za
                    nákup voucheru. speedlo neodpovídá za škody vzniklé v souvislosti s užitím informací
                    zveřejněných na Portálu speedlo.
                </li>
                <li>
                    Kliknutím na některé odkazy na Portálu speedlo může dojít k opuštění Portálu speedlo a k
                    přesměrování na webové stránky třetích subjektů.
                </li>
                <li>
                    speedlo si vyhrazuje právo kdykoliv omezit či ukončit přístup Uživatele na Portál
                    speedlo.
                </li>
                <li>
                    Uživatel nese odpovědnost za veškerou škodu vzniklou neoprávněným zásahem Uživatele do
                    Portálu speedlo či systému, který realizuje přenos z Portálu speedlo třetím osobám.
                </li>
            </ol>
            <h3>III. Autorská práva</h3>
            <ol>
                <li>speedlo je výlučným vlastníkem Portálu speedlo.</li>
                <li>Portál speedlo je autorským dílem ve smyslu zákona č. 121/2000 Sb., autorský zákon, v
                    platném znění. speedlo vykonává veškerá majetková práva vztahující se k Portálu speedlo.</li>
                <li>Obsah Portálu speedlo nelze uchovávat, upravovat, šířit ani k němu vykonávat jiná
                    majetková práva, ledaže k takovému jednání speedlo udělilo předem souhlas.</li>
            </ol>
            <h3>IV. Práva a povinnosti Uživatele</h3>
            <ol>
                <li>
                    Uživatel vůči speedlo prohlašuje a zaručuje, že:
                    <ol>
                        <li>je způsobilý k právním jednáním, a to zejména s ohledem na svůj věk, nebo je zastoupen
                            zákonným zástupcem,</li>
                        <li>veškeré údaje, které poskytuje společnosti speedlo, jsou pravdivé, úplné, přesné a
                            správné,</li>
                        <li>
                            se před zahájením užívání Portálu speedlo důkladně seznámil s těmito Podmínkami, že
                            těmto podmínkám zcela rozumí a souhlasí s nimi,
                        </li>
                        <li>
                            bude používat portál speedlo a nakupovat na něm prostřednictvím ne více než jednoho
                            uživatelského účtu. V případě oprávněného podezření, že Uživatel vytvořil a užívá více
                            uživatelských účtů, má speedlo právo tyto účty zablokovat a smazat.
                        </li>
                    </ol>
                </li>
                <li>
                    Uživatel je povinen při užívání Portálu speedlo dodržovat právní předpisy a dále je
                    povinen vždy respektovat práva speedlo i třetích osob, zejména při nakládání s
                    autorskými díly a jinými předměty práv duševního vlastnictví. Uživatel zejména nesmí:
                    <ol>
                        <li>užívat Portál speedlo v rozporu s těmito Podmínkami,</li>
                        <li>komerčně užívat kterékoli části Portálu speedlo.cz způsobem způsobilým poškodit speedlo
                            a/nebo Zájemce,</li>
                        <li>získávat přihlašovací jména a/nebo hesla jiných Uživatelů,</li>
                        <li>zneužívat, blokovat, modifikovat či jinak měnit jakoukoliv součást Portálu speedlo, nebo
                            se i jen pokusit narušit stabilitu, chod nebo data Portálu speedlo,</li>
                        <li>používat mechanismy, programové vybavení nebo jiné postupy, které by mohly mít negativní
                            vliv na provoz Portálu speedlo. Portál speedlo je možné užívat jen v rozsahu, který není
                            na úkor práv ostatních Uživatelů nebo speedlo a který je v souladu s jeho určením.</li>
                    </ol>
                </li>
                <li>
                    Uživatel se, bez ohledu na jiná ustanovení těchto Podmínek, zavazuje, že:
                    <ol>
                        <li>nebude používat Portál speedlo, pokud by jeho použitím ze strany Uživatele došlo k
                            porušení právních předpisů,</li>
                        <li>pokud je mu méně než 18 let, nebude používat takové služby Portálu speedlo.cz, u nichž
                            je podmínkou pro užívání minimální věk 18 let.</li>
                    </ol>
                </li>
                <li>
                    Nebude-li možno spolehlivě ověřit, že Uživatel je starší 18-ti let, k předání využité
                    služby a/nebo zboží Zájemce podléhající minimální věkové hranici 18-ti let, který je
                    předmětem Zprostředkované smlouvy, nedojde a automaticky dojde Zájemcem k odstoupení od
                    Zprostředkované smlouvy uzavřené mezi Zájemcem a Zákazníkem.
                </li>
                <li>Uživatel bere na vědomí, že pro účely splnění Zprostředkované smlouvy, bude Zájemcem či
                    speedlo kontaktován prostřednictvím e-mailu, popř. prostřednictvím telefonního čísla,
                    který společnosti speedlo poskytl, a to v souladu s podmínkami zpracování osobních údajů
                    zveřejněných a volně dostupných na Portále speedlo. Uživatel je proto povinen do doby
                    doručení služeb a/nebo zboží Zájemce očekávat kontaktování doručující osoby (kurýra).</li>
            </ol>
            <h3>V. Cena za využití služby a/nebo zboží Zájemce a platební podmínky</h3>
            <ol>
                <li>Uživatel bere na vědomí, že konečná cena objednávky je uváděna po zaokrouhlení na celé
                    koruny v souladu s příslušnými právními předpisy. Podrobný rozpis kupní ceny, vč.
                    haléřů, je vždy uveden na dodacím listu a na daňovém dokladu vystavený Podnikem.</li>
                <li>
                    Cena za využité služby a/nebo zboží Zájemce a případné další náklady (např. cenu
                    doručení) hradí Uživatel jedním z následujících způsobů:
                    <ol>
                        <li>bezhotovostně platební kartou prostřednictvím zabezpečené internetové platební brány
                            poskytované společnosti ČSOB, PayU, nebo GoPay která je součástí Portálu speedlo;</li>
                        <li>hotově při převzetí;</li>
                        <li>stravenkami (maximálně však 5 ks na jednu objednávku);</li>
                        <li>jiným způsobem dle aktuální nabídky Portálu speedlo.</li>
                    </ol>
                </li>
                <li>Cena za využití služby a/nebo zboží Zájemce jsou uvedeny vč. DPH.</li>
                <li>Platba bezhotovostně platební kartou: Zvolil-li Uživatel jako způsob placení platbu
                    bezhotovostní platební kartou, postupuje během placení dle kroků sdělených mu v rámci
                    příslušné internetové platební brány. speedlo není povinen služby a/nebo zboží
                    zprostředkovat a Podnik není povinen služby a/nebo zboží z objednávky Uživateli
                    poskytnout před tím, než bude cena za využití služby a/nebo zboží Uživatelem uhrazena.
                    Má-li Uživatel k úhradě ceny služby a/nebo zboží v úmyslu využít platební bránu ČSOB
                    (internetovou platební bránu) provozovanou společností Československá obchodní banka,
                    a.s., se sídlem Praha 5, Radlická 333/150, PSČ 15057, IČO: 00001350, je před jéjím
                    použitím povinen seznámit se s příslušnými obchodními podmínkami upravujícími užívání
                    této brány. Platební bránu ČSOB je provozována výhradně společnosti Československá
                    obchodní banka, a.s. Proto speedlo a Podnik nenesou jakoukoli odpovědnost za případnou
                    škodu způsobenou Uživateli na základě použití tohoto platebního systému.</li>
                <li>Platbou hotově při převzetí služeb - Zvolí-li Uživatel způsob placení hotově při
                    převzetí služby a/nebo zboží při převzetí, je cena splatná při předání služby a/nebo
                    zboží z objednávky. Cena služby a/nebo zboží se považuje za zaplacenou v okamžiku
                    předání příslušné částky doručující osobě (kurýrovi) při převzetí dodané služby a/nebo
                    zboží.</li>
                <li>Při platbě stravenkami popř. stravenkovou kartou se Uživatel zavazuje, že skrz Portál
                    speedlo objedná pouze služby a/nebo zboží neobsahující alkoholické nápoje.</li>
                <li>Uživatel rovněž bere výslovně na vědomí, že pokud pro dokončení objednávky učiněné skrz
                    Portál speedlo zvolí metodu platby listinnými stravenkami, může dojít k situaci, že
                    Zájemce vydavatele listinných stravenek Uživatele neakceptuje. Nastane-li tato
                    skutečnost, je Uživatel povinen objednávku uhradit hotovostí.</li>
                <li>Udělí-li Zákazník doručující osobě spropitné za rozvoz služby a/nebo zboží Zájemce není
                    takto udělené spropitné příjmem speedlo.</li>
                <li>Uživateli je rovněž umožněno hradit cenu objednávky poukazy, které speedlo Uživateli v
                    rámci péče o zákazníky udělí.</li>
            </ol>
            <h3>VI. Závěrečná ustanovení</h3>
            <ol>
                <li>Veškerá jídla, pokrmy, nápoje a/nebo zboží nabízená Zájemcem Zákazníkům prostřednictvím
                    Portálu speedlo mohou obsahovat alergeny. Pro bližší informace ohledně alergenů
                    obsažených v konkrétních jídlech, pokrmech, nápojích a/nebo jiném zboží, mohou Zákazníci
                    kontaktovat zákaznickou podporu prostřednictvím adresy <a href="mailto:info@speedlo.cz">info&#064;speedlo.cz</a>. Zákaznická
                    podpora poskytne informace o jejich přesném složení v příslušném pokrmu. Tyto informace
                    jsou Zákazníkům k dispozici i v okamžiku doručení, a to u příslušného kurýra.</li>
                <li>V souladu s ustanovením § 1829 občanského zákoníku má Zákazník právo od Zprostředkované
                    smlouvy odstoupit do 14 dní od převzetí zboží a/nebo služeb a to i od jednotlivé položky
                    zboží a/nebo služeb. V případě odstoupení od Zprostředkované smlouvy Zákazník může
                    předat zboží a/nebo služby ihned osobě, která mu jej za speedlo předala a není-li to
                    možné, je povinen sdělit odstoupení od Smlouvy telefonicky speedlo nebo prostřednictvím
                    emailu <a href="mailto:info@speedlo.cz">info&#064;speedlo.cz</a> a zaslat zboží a/nebo služby na své náklady na adresu Zájemce
                    (obecně na adresu jeho provozovny, případně v sídle či místě podnikání Zájemce a/nebo
                    prostřednictvím kontaktních údajů uvedených u Zájemce na jeho profilu Portále speedlo).
                    Pro vyloučení veškerých pochybností speedlo v souladu s příslušnými ustanoveními
                    Občanského zákoníku prohlašuje, že nelze odstoupit od Zprostředkované smlouvy týkající
                    se zboží a/nebo služeb podléhající rychlé zkáze (např. ovoce, zelenina nebo potraviny
                    živočišného původu) nebo u zboží a/nebo služeb, které bylo po dodání nenávratně smíseno
                    s jiným zbožím a/nebo služeb. Výjimkou je ovoce a zelenina, která má na obalu uvedenou
                    jinou zemi původu, než je uvedeno na Portále speedlo. Zákazník dále nemůže odstoupit od
                    Zprostředkované smlouvy, týká-li se zboží a/nebo služby, které bylo dodáno v uzavřeném
                    obalu, které Zákazník z obalu vyňal a z hygienických důvodů jej není možné vrátit.
                    Zákazník též nemůže odstoupit od Zprostředkované smlouvy o dodávce novin, periodik nebo
                    časopisů, jakož i o dodávce zvukové nebo obrazové nahrávky nebo počítačového programu,
                    pokud porušil jejich obal. V případě platného odstoupení od Zprostředkované smlouvy bude
                    cena zboží a/nebo služeb nebo její poměrná část vrácena do 14 dnů od vrácení zboží
                    a/nebo služeb na bankovní účet Zákazníka, případně na bankovní účet, z nějž Zákazník
                    cenu uhradil a to včetně nákladů na dodání nebo jejich poměrné části.</li>
                <li>Práva a povinnosti mezi Zájemcem a Uživatelem ohledně práv z vadného plnění se řídí
                    příslušnými obecně závaznými právními předpisy (zejména ustanoveními § 1914 až 1925, §
                    2099 až 2117 a § 2161 až 2174 zákona č. 89/2012 Sb., občanského zákoníku a zákonem č.
                    634/1992 Sb., o ochraně spotřebitele, ve znění pozdějších předpisů). Uživatel v
                    postavení spotřebitele má právo u Zájemce uplatnit práva z vadného plnění v souvislosti
                    se Zprostředkovanou smlouvou u Zájemce (obecně na adrese jeho provozovny, v níž je
                    uplatnění práv z vadného plnění možné s ohledem na sortiment prodávaného zboží, případně
                    i v sídle nebo místě podnikání Zájemce) a/nebo prostřednictvím e-mailové adresy&nbsp;
                    <a href="mailto:info@speedlo.cz">info&#064;speedlo.cz</a> a to s přihlédnutím ke skutečnosti, že předmětem Zprostředkované smlouvy
                    je koupě zuživatelného zboží (jídlo a nápoje), které podléhá rychlé zkáze.</li>
                <li>K mimosoudnímu řešení spotřebitelských sporů je příslušná Česká obchodní inspekce, se
                    sídlem Štěpánská 567/15, 120 00 Praha 2, IČ: 000 20 869, internetová adresa:&nbsp;
                    <a href="https://www.coi.cz/" target={"_blank"}>https://www.coi.cz</a>.</li>
                <li>Veškeré právní vztahy vznikající na základě nebo v souvislosti s Portálem speedlo se
                    řídí právním řádem České republiky, a to bez ohledu na to odkud byl přístup a užití
                    Portálu speedlo realizován.</li>
                <li>Je-li nebo stane-li se některé z ustanovení Podmínek neplatným nebo neúčinným, namísto
                    takových neplatných ustanovení nastoupí ustanovení, jehož smysl se neplatnému ustanovení
                    co nejvíce přibližuje. Neplatností nebo neúčinností některého ustanovení není dotčena
                    platnost a účinnost ostatních ustanovení.</li>
                <li>Tyto Podmínky nabývají účinnosti dne 26.06.2020.</li>
            </ol>
            <p>V Pardubicích dne 26.06.2020</p>
        </Fragment>
    )
};

export default TermsCZ;