import {gql} from "apollo-boost";

export const GET_BRANCHES = gql`
    query getBranches($addressSearch: String!){
        companyBranchesLookUp(address: $addressSearch) {
            finalAddressFormatted
            isResolved
            isValidAddress
            resolvedBranches {
                url
                companyBranch {
                    id
                    brand {
                        url
                        subdomain
                    }
                    name
                    pickupEnabled
                    headerText
                    urlPath
                    
                    isOpen
                    businessHoursIntervals {closedNote}
                    willOpenAt
                    willCloseAt
                    defaultDeliveryTime
                    defaultDeliveryTimeEnabled
                    enabled
                    styles{storeLogo}
                    android:mobileApplicationSetting(platform: ANDROID) {
                        url
                    }
                    ios:mobileApplicationSetting(platform: IOS) {
                        url
                    }
                    pwa:mobileApplicationSetting(platform: PWA) {
                        url
                    }
                }
            }
        }
    }
`;
