import React, {Component, Fragment} from 'react';
import Tabs from "../../Components/Tabs";
import FoodList from "../../Components/FoodList";
import RestaurantInfo from "../../Components/RestaurantInfo";
import BranchHeader from "../../Components/BranchHeader";
import {Translation} from 'react-i18next';

class RestaurantDetailData extends Component {

    constructor(props) {
        super(props);
        this.state = {
            data: this.props.branchData,
            selected: 0
        };
    }

    render() {
        return (
            <Translation>
                {t =>
                    <Fragment>
                        <BranchHeader item={this.state.data.companyBranch}/>
                        <div className="container">
                            <Tabs
                                selected={this.state.selected}
                                onSelectTab={(key) => this.setState({selected: key})}
                                data={[
                                    {
                                        name: t('offer'),
                                        component: <FoodList data={this.state.data.wareCategories}/>,
                                    },
                                    {
                                        name: t('about_restaurant'),
                                        component: <RestaurantInfo data={this.state.data.companyBranch}/>,
                                    }
                                ]}
                            />
                        </div>
                    </Fragment>
                }
            </Translation>
        )
    }
}

export default RestaurantDetailData;