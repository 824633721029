import React, {Component} from "react";
import {Link} from "react-router-dom";
import {Translation} from 'react-i18next';

class NotFound extends Component {

    render() {
        return (
            <Translation>
                {t =>
                    <div className={"NotFound"}>
                        <div className={"container"}>
                            <div className="row">
                                <div className="col-lg-12 text-center">
                                    <img src="/portal_static/img/404.png" className={"img-fluid"} draggable={false}
                                         alt="404"/>
                                    <h2>{t('page_not_found')}</h2>
                                    <div className="back-to-home">
                                        <Link className={"primary-btn"} to={"/"}>{t('go_back_home')}</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                }
            </Translation>
        )
    }
}

export default NotFound;