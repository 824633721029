import React, { Fragment } from 'react';

import { Link } from 'react-router-dom';

const PersonalInformationEN = () => {
  return (
    <Fragment>
      <h2>1. Who are we and how can you contact us?</h2>
      <p>
        When you use our food ordering services via a specially set-up portal or
        via an app available from the AppStore and GooglePlay (both hereinafter
        jointly referred to as the “Portal”), we, [NAME OF FOOD SELLER]
        (hereinafter referred to as “we”), are the controller of your personal
        data. A “personal data controller” is an entity determining the purposes
        and means of processing, i.e. an entity deciding why and how personal
        data is to be processed.
      </p>
      <p>
        Our contact details are provided below in case you are unable to find
        answers to your questions or wish to clarify any of the information in
        this document.
      </p>
      <p>
        Email: <a href="mailto:info@speedlo.cz">info@speedlo.cz</a>
      </p>
      <p>Mail: Hlaváčova 207, 530 02 Pardubice</p>
      <p>
        The Portal is managed by speedlo s.r.o., registration number: 08750866,
        registered office: Hlaváčova 207, Zelené Předměstí, 530 02 Pardubice,
        which developed it for us and acts as a personal data processor for your
        personal data when you place an order. In certain circumstances, it may
        also act as a personal data controller, in which case the processing of
        personal data is governed by its privacy policy here:{" "}
        <Link to="/zasady-ochrany-osobnich-udaju">zde:</Link>{" "}
        (https://speedlo.cz/zasady-ochrany-osobnich-udaju)
      </p>
      <hr />
      <h2>2. What personal data do we process and how do we obtain it?</h2>
      <p>
        You yourself are our primary source of your personal data, which we
        obtain in the following ways:
      </p>
      <ul>
        <li>
          <h3>When you order food from the restaurant</h3>
          <p>
            So that you can place an order through our Portal, we will need your
            contact details, in particular your name, email, phone number, and
            contact address. We will also need information about how you are
            going to pay for your order. Each time you make a purchase through
            our Portal, we log information relating to the purchase (i.e. your
            purchase history, information about the food ordered, and
            information on the place of delivery).
          </p>
          <p>
            If you choose to pay via the payment gateway, we will use the
            payment gateway provider and speedlo, the company that runs the
            technical aspects of the payment gateway, to process the information
            entered.
          </p>
        </li>
        <li>
          <h3>When you use our services</h3>
          <p>
            When you use our services, certain information may be collected
            passively. This includes, but is not limited to, your IP address,
            browser type, and operating system type. We also use cookies and
            navigation data to collect information on the date and time of your
            visit and the information you searched for and viewed. When you use
            our mobile apps, we may also obtain information about how, on your
            device, you use our services, including information about your
            location, if you have enabled this feature on your mobile device.
          </p>
        </li>
      </ul>
      <hr />
      <h2>3. Why do we process your personal data?</h2>
      <h3>Access to our Platform</h3>
      <p>
        We process your personal data in order to give you access to our
        services (Platform) and to enable you to place an order with us.
      </p>
      <p>
        We process personal data for the above purpose on the legal grounds that
        we are providing contractual performance.
      </p>
      <h3>Administration of your order</h3>
      <p>
        After you have placed an order via our Platform, we need to process it
        internally. In addition, either we or the processor (appointed by us)
        may contact you about your purchase, e.g. to update you on the status of
        your order (by email, phone, SMS, or via our mobile app).
      </p>
      <p>
        We process personal data for the above purpose on the legal grounds that
        we are providing contractual performance.
      </p>
      <h3>Management and improvement of our services</h3>
      <p>
        Your data is also used to enable us to manage, improve, and develop our
        services by, for example, analysing it. We may also use your personal
        data to resolve any irregularities or errors, or any complaints you may
        have.
      </p>
      <p>
        We process your personal data for the above purposes on the grounds that
        this is a legitimate interest (in improving our services).
      </p>
      <h3>Fraud prevention and compliance with legal obligations</h3>
      <p>
        As a restaurant, we need to store your personal data in order to comply
        with our legal obligations, in particular those arising from accounting
        and tax regulations. In the event of a dispute, we may also use your
        personal data to protect our interests and use it in any legal or other
        proceedings.
      </p>
      <p>
        We process personal data for the above purpose on the legal grounds that
        this is a legitimate interest (in protecting our own interests) and that
        we need to comply with legal requirements.
      </p>
      <h3>Feedback and important changes</h3>
      <p>
        We also use your personal data to obtain feedback from you on the
        services we provide. From time to time, we will also notify you of
        important changes to the services we provide.
      </p>
      <p>
        We process personal data for the above purposes on the legal grounds
        that this is a legitimate interest (in improving the services we
        provide).
      </p>
      <h3>Personalisation of our services</h3>
      <p>
        We would like what we offer you via and in connection with our Portal to
        match what you are really interested in as closely as possible. To
        achieve this, we may use your personal data to help us understand this
        better, or at least try to do so. This also enables us to tailor our
        communications to you so that they are as relevant and interesting as
        possible to you.
      </p>
      <p>
        We process personal data for the above purpose on the legal grounds that
        this is a legitimate interest (in adapting the services we provide).
      </p>
      <h3>Marketing communications</h3>
      <p>
        We also use your contact details to send you information about offers
        that may be of interest to you, or to send you various interesting
        discount coupons. For this purpose, we will contact you primarily by
        email, SMS, or our mobile app.
      </p>
      <p>
        We process personal data for the above purposes on the legal grounds
        that this is a legitimate interest (in direct marketing). We may also
        solicit consent for these marketing communications. You will know the
        legal reason for processing in this respect because we will give you the
        choice, when you complete your order, to either consent to receive
        marketing communications or opt-out of receiving marketing
        communications.
      </p>
      <h2>4. How long we process and store your personal data</h2>
      <p>
        We will only process and store your personal data for as long as
        necessary for the purposes set out above, or for as long as necessary to
        comply with legal requirements. After that, we will delete or anonymise
        your personal data.
      </p>
      <p>
        PIf we process your personal data on the basis of a legitimate interest,
        it is processed for as long as our legitimate interest lasts. Where your
        personal data is processed on the basis of a legitimate interest, you
        may object to such processing at any time.
      </p>
      <p>
        In the case of marketing communications, we will retain your personal
        data until you opt out of receiving marketing communications from us.
        The method of refusal (or the right and method to withdraw consent) is
        stated in each individual marketing communication.
      </p>
      <hr />
      <h2>5. Who do we disclose your personal data to?</h2>
      <p>We share your personal data with the following recipients:</p>
      <ul>
        <li>
          <p>
            speedlo s.r.o., registration number: 08750866, registered office:
            Hlaváčova 207, Zelené Předměstí, 530 02 Pardubice, which developed
            the Portal for us and which acts as the processor of the personal
            data you provide when you place an order; it may also engage in
            other activities, such as the storage of personal data, marketing,
            the checkout system, etc.;
          </p>
        </li>
        <li>
          <p>
            depending on the payment method you choose for your order, in
            certain cases we share your data with the provider of that payment
            method, e.g. when you decide to pay via GOPAY (your personal data is
            transferred to GOPAY s.r.o., registration number: 260 46 768,
            registered office: Planá 67, 370 01 Planá); alternatively, you may
            use the ČSOB payment gateway (in which case your personal data is
            transferred to Československá obchodní banka, a. s., 00001350,
            registered office: Praha 5, Radlická 333/150, 15057); if you decide
            to pay by credit or debit card, your interaction takes place with
            the relevant payment gateway operator and with speedlo, which helps
            to provide technical support for payments;
          </p>
        </li>
        <li>
          <p>
            our suppliers, in particular the people who transport your orders,
            as well as suppliers of IT, hosting and marketing services,
            providers of statistical, database and other software tools, and,
            where appropriate, other entities that provide services to us.
          </p>
        </li>
      </ul>
      <p>
        There are certain circumstances where we may be required by law to share
        your personal data with third parties (such as law enforcement agencies)
        without your consent. This is a legal obligation for us.
      </p>
      <hr />
      <h2>6. How do we process your personal data and how is it secured?</h2>
      <p>
        Personal data is processed both manually and automatically in electronic
        information systems, electronically, or on paper. Your personal data is
        processed primarily by our employees and processors. We have taken
        appropriate organisational and security measures to protect your
        personal data from any accidental loss, destruction, misuse, damage, or
        unauthorised or unlawful access. To ensure the adequate protection of
        your personal data, we have entered into the corresponding contracts
        with processors who process your personal data for us in our position of
        data controller. Employees and processors involved in the processing of
        your personal data are bound by confidentiality.
      </p>
      <hr />
      <h2>7. What are your personal data protection rights?</h2>
      <p>
        You have the following rights, which you may exercise at any time during
        the processing of your personal data by using, for example, the email
        address above:
      </p>
      <ul>
        <li>
          <h3>Right of access.</h3>
          <p>
            You have the right to obtain confirmation of whether we process
            personal data about you, and if so what personal data. This includes
            the right to receive copies of the personal data we process. Please
            note that it will not be possible to disclose information that would
            compromise our business secrets or the rights of third parties.
          </p>
        </li>
        <li>
          <h3>Right to rectification and completion.</h3>
          <p>
            If you discover that the personal data we process about you is
            inaccurate or incomplete, you have the right to ask us to correct
            and update it.
          </p>
        </li>
        <li>
          <h3>Right to erasure.</h3>
          <p>
            In certain cases, you have the right to contact us to request the
            erasure of the personal data we process about you. Please note that
            the erasure of personal data is not always possible, especially if
            it is processed for a legitimate purpose and in accordance with data
            protection regulations.
          </p>
        </li>
        <li>
          <h3>Right to restriction of processing.</h3>
          <p>
            In certain cases, such as when you doubt the accuracy or lawfulness
            of the processing of your personal data, or our need to process it,
            you have the right to ask us to restrict the processing of your
            personal data. If we comply with your request, we will restrict the
            processing of your personal data to the minimum necessary (and, as a
            rule, we will not actively process the data further). We will make a
            record in our systems that the data is subject to restriction. If
            the restriction is lifted and we plan to continue processing your
            personal data, we will inform you of this in advance.
          </p>
        </li>
        <li>
          <h3>Right to portability.</h3>
          <p>
            You have the right to request that we transfer the personal data
            that you have provided to us and that we process (in a commonly used
            and machine-readable format) to you or a third party (another data
            controller) specified in your request. Please note that you can only
            exercise this right if we process your personal data on the basis of
            your consent or for the purposes of a contract and the processing is
            automated.
          </p>
        </li>
        <li>
          <h3>Right to object.</h3>
          <p>
            You may object to the processing of your personal data that we
            process for direct marketing purposes or on the basis of a
            legitimate interest. If you object to processing for direct
            marketing purposes, your personal data will no longer be processed
            for such purposes. If you object to processing on other grounds, we
            will evaluate the objection and then inform you whether we have
            accepted your objection and will no longer process your data, or
            whether the objection was not justified and processing will
            continue. In any event, processing will be restricted until the
            objection is resolved.
          </p>
        </li>
        <li>
          <h3>Right to lodge a complaint with a supervisory authority.</h3>
          <p>
            If you believe that there has been a breach of data protection
            legislation, you have the right to lodge a complaint with the Office
            for Personal Data Protection or with another authority of a Member
            State of the European Union responsible for supervising compliance
            with data protection obligations. As you are important to us, we
            would be highly appreciative if you contacted us directly before
            lodging a complaint, so that we have an opportunity to resolve the
            entire matter with you directly.
          </p>
        </li>
      </ul>
    </Fragment>
  );
};

export default PersonalInformationEN;
