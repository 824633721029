import React from "react";
import PropTypes from "prop-types";

const HeaderStaticPage = ({headline, title}) => {

    return (
        <header>
            <h1>{headline}</h1>
        </header>
    )
};


HeaderStaticPage.propTypes = {
    headline: PropTypes.string.isRequired,
    title: PropTypes.string,
};

export default HeaderStaticPage;