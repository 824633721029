import {gql} from "apollo-boost";

export const GET_BRANCH = gql`
    query getBranch($branchId: ID!){
        companyBranch(id: $branchId) {
            name
            isOpen
            businessHoursIntervals {
                closedNote
            }
            headerText
            homepageUrl
            styles {
                storeLogo
                main
                mainSecondary
            }
            willOpenAt
            willCloseAt
            address {
                gps {latitude, longitude}
                location {
                    city
                    street
                    houseNumber
                    gps {latitude, longitude}
                }
            }
            brand {
                url
                subdomain
            }
            phone
            android: mobileApplicationSetting(platform: ANDROID) {
                url
            }
            ios: mobileApplicationSetting(platform: IOS) {
                url
            }
            pwa: mobileApplicationSetting(platform: IOS) {
                url
            }
        }
        wareCategories(
            companyBranch: $branchId
            enabled: true
            orderOriginCategory: WEBSITE
            isAvailable: true
        ) {
            wareCategoryType {
                enum
            }
            nameLabel
            recipes {
                nameLabel
                descriptionLabel
                image
                enabled
                prices {
                    warePriceType {
                        code
                    }
                    price {
                        formattedValue
                    }
                }
            }
        }
    }
`;
