import React from "react";
import PropTypes from "prop-types";
import {Translation} from 'react-i18next';

const RestaurantInfo = ({data}) => {

    return (
        <Translation>
            {t =>
                <div className={"RestaurantInfo"}>
                    <div className="row">
                        <div className="col-lg-8">
                            <div className="row">
                                <div className="col-lg-3 text-muted">
                                    {t('name')}
                                </div>
                                <div className="col-lg-9">
                                    {data.name}
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-3 text-muted">
                                    {t('description')}
                                </div>
                                <div className="col-lg-9">
                                    {data.headerText}
                                </div>
                            </div>
                            {data.homepageUrl &&
                            <div className="row">
                                <div className="col-lg-3 text-muted">
                                    {t('web')}
                                </div>
                                <div className="col-lg-9">
                                    <a href="{data.homepageUrl}" target={"_blank"}>{data.homepageUrl}</a>
                                </div>
                            </div>
                            }

                        </div>
                        <div className="col-lg-4">
                            {t('map')}
                        </div>
                    </div>
                </div>
            }
        </Translation>
    )
};

RestaurantInfo.propTypes = {
    data: PropTypes.object.isRequired
};

export default RestaurantInfo;