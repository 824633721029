export const BASE_COOKIE_CONFIG = {
  languages: {
    cs: {
      consent_modal: {
        title: "Používáme sušenky!",
        description:
          'Tyto webové stránky používají k poskytování služeb, personalizaci reklam a analýze návštěvnosti soubory cookies. Některé z nich jsou k fungování stránky nezbytné, ale o některých můžete rozhodnout sami. <button type="button" data-cc="c-settings" class="cc-link">Nastavení cookies</button>',
        primary_btn: {
          text: "Povolit vše",
          role: "accept_all",
        },
        secondary_btn: {
          text: "Zamítnout vše",
          role: "accept_necessary",
        },
      },
      settings_modal: {
        title: "Nastavení cookies",
        save_settings_btn: "Uložit nastavení",
        accept_all_btn: "Povolit vše",
        reject_all_btn: "Zamítnout vše",
        close_btn_label: "Zavřít",
        cookie_table_headers: [
          { col1: "Název" },
          { col2: "Doména" },
          { col3: "Expirace" },
          { col4: "Popis" },
        ],
        blocks: [
          {
            title: "Využití souborů cookies",
            description:
              'Soubory cookies používáme k zajištění základních funkcí webu a ke zlepšení Vašeho zážitku z návštěvy. Pro každou kategorii si můžete zvolit, zda chcete nebo nechcete, abychom tato data sbírali. Pro více informací si přečtěte celé <a href="https://speedlopro.cz/cookies-a-zpracovani-udaju.pdf" class="cc-link">cookies a zpracování dokumentů</a>.',
          },
          {
            title: "Nezbytně nutné cookies",
            description:
              "Tyto cookies používáme pro zajištění nezbytných funkcí web, jsou tedy vždy povolené.",
            toggle: {
              value: "necessary",
              enabled: true,
              readonly: true,
            },
          },
          {
            title: "Analytické a reklamní cookies",
            description:
              "Cookies sloužící pro sledování Vašeho chování na našem webu a cílení reklamních oznámení.",
            toggle: {
              value: "analytics",
              enabled: false,
              readonly: false,
            },
            cookie_table: [
              {
                col1: "^_ga",
                col2: "google.com",
                col3: "2 years",
                col4: "Permanent cookie",
                is_regex: true,
              },
              {
                col1: "_gid",
                col2: "google.com",
                col3: "1 day",
                col4: "Permanent cookie",
              },
              {
                col1: "^_fbp",
                col2: "speedlo.cz",
                col3: "3 měsíce",
                col4: "Cookies záznam společnosti Meta Inc. (dříve Facebook) pro sledování návštěvnosti a cílení reklamích oznámení.",
                is_regex: true,
              },
              {
                col1: "fr",
                col2: ".facebook.com",
                col3: "3 měsíce",
                col4: "Cookies záznam společnosti Meta Inc. (dříve Facebook) pro sledování chování uživatelů za účelem cílení reklamy.",
              },
            ],
          },
          {
            title: "Více informací",
            description:
              'Pro více informací ohledně cookies a ochrany osobních údajů nás kontaktujte na emailu <a class="cc-link" href="mailto:info@speedlopro.cz">info@speedlopro.cz</a>',
          },
        ],
      },
    },
  },
};
