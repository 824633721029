import React, {Component} from "react";
import {Field, Form} from "react-final-form";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import cogoToast from "cogo-toast";
import axios from "axios";
import {Translation, Trans} from 'react-i18next';


class ContactForm extends Component {

    constructor() {
        super();

        this.state = {
            successForm: false,
            formMessage: '',
            formStatus: ''
        }
    }

    onSubmit = async values => {
        if (!values.personal_info) {
            cogoToast.error(<Trans i18nKey={'error_agree_terms'}/>);

        } else {
            let mess;
            let messStatus;
            axios.post("https://proc.speedlo.cz/api/v1/contactform",
                {
                    name: values.name,
                    email: values.email,
                    note: values.note
                },
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'Token': process.env.REACT_APP_API_TOKEN
                    }
                }).then((response) => {
                if (response.data.result === true) {
                    mess = <Trans i18nKey={'message_sent'}/>;
                    messStatus = 'success';
                } else {
                    mess = <Trans i18nKey={'error_something_went_wrong_try_later'}/>;
                    messStatus = 'error';
                }
                this.setState({
                    formMessage: mess,
                    formStatus: messStatus
                })
            }).catch(err => console.error(err));

            this.setState({
                successForm: !this.state.successForm,
            })
        }

    };

    render() {
        return (
            <Translation>
                {t =>
                    <div className={"ContactForm"}>
                        {!this.state.successForm ?
                            <Form
                                onSubmit={this.onSubmit}
                                validate={values => {
                                    const errors = {};
                                    if (!values.name) {
                                        errors.name = t('field_is_required')
                                    } else if (values.name.length > 35) {
                                        errors.name = t('name_is_too_long')
                                    }
                                    if (!values.email) {
                                        errors.email = t('field_is_required')
                                    } else if (values.email.length > 35) {
                                        errors.email = t('name_is_too_long')
                                    }
                                    if (!values.note) {
                                        errors.note = t('field_is_required')
                                    } else if (values.note.length > 600) {
                                        errors.note = t('name_is_too_long')
                                    }
                                    return Object.keys(errors).length ? errors : ''
                                }}
                                render={({handleSubmit, form, submitting, pristine, values}) => (
                                    <form onSubmit={handleSubmit}>
                                        <h3>{t('write_us')}</h3>
                                        <div className="row mb-2">
                                            <div className="col-md-6">
                                                <div className="md-form mb-2">
                                                    <Field name="name" validate={this.required}>
                                                        {({input, meta}) => (
                                                            <div>
                                                                <label htmlFor="name">* {t('your_name')}</label>
                                                                <input {...input} type="text" placeholder={t('your_name')}
                                                                       className={"form-control"}/>
                                                                {meta.error && meta.touched &&
                                                                <span>{meta.error}</span>}
                                                            </div>
                                                        )}
                                                    </Field>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="md-form mb-2">
                                                    <Field name="email" validate={this.required}>
                                                        {({input, meta}) => (
                                                            <div>
                                                                <label htmlFor="email">* E-mail</label>
                                                                <input {...input} type="email" placeholder="E-mail"
                                                                       className={"form-control"}/>
                                                                {meta.error && meta.touched &&
                                                                <span>{meta.error}</span>}
                                                            </div>
                                                        )}
                                                    </Field>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row mb-1">
                                            <div className="col-md-12">
                                                <div className="md-form">
                                                    <Field name="note" validate={this.required}>
                                                        {({input, meta}) => (
                                                            <div>
                                                                <label htmlFor="note">* {t('message')}</label>
                                                                <textarea {...input} placeholder={t('message')}
                                                                          className={"form-control md-textarea"}/>
                                                                {meta.error && meta.touched &&
                                                                <span>{meta.error}</span>}
                                                            </div>
                                                        )}
                                                    </Field>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-lg-12">
                                                <label className={"mb-4 d-flex align-items-center"}>
                                                    <Field
                                                        name="personal_info"
                                                        component="input"
                                                        type="checkbox"
                                                        className={"mr-1"}
                                                    />{' '}
                                                    {t('i_agree_with_personal_data')}
                                                </label>
                                                <div className="text-center text-md-left">
                                                    <button className={"btn primary-btn"} type="submit"
                                                            disabled={submitting || pristine}>
                                                        {t('send')} <FontAwesomeIcon icon={"paper-plane"}/>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                )}
                            />
                            :
                            <div className={`ContactForm_success type-${this.state.formStatus}`}>
                                <div>{this.state.formMessage}</div>
                            </div>
                        }
                    </div>
                }
            </Translation>
        )
    }
}

export default ContactForm;