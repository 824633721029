import React, { Component, Fragment } from "react"

import DocumentTitle from "react-document-title"
import { Translation } from "react-i18next"
import { connect } from "react-redux"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

import {
  actionFetchBranchesDone,
  actionFetchBranchesError,
  actionFetchBranchesStart,
} from "../../../actions/branchesAction"
import BranchItem from "../../Components/BranchItem"
import ChangeAddressForm from "../../Components/ChangeAddressForm"
import HelperRefetchData from "../../Components/HelperRefetchData"
import LoadingOverlay from "../../Components/LoadingOverlay"
import { StringWebalizer } from "../../Components/StringWebalizer"

class RestaurantList extends Component {
  constructor() {
    super()
    this.state = {
      deliveryType: true,
      reqForm: false,
    }
  }

  componentDidMount() {
    window.scrollTo(0, 0)
  }

  deliveryType = () => {
    this.setState({
      deliveryType: !this.state.deliveryType,
    })
  }

  generateTitle = () => {
    if (this.props.data) {
      let address = this.props.data.companyBranchesLookUp.finalAddressFormatted
      let city = address.split(", ")
      return <DocumentTitle title={"Rozvoz jídla " + city[1] + " a okolí"} />
    }
  }

  _dataRender = () => {
    if (!this.state.reqForm) {
      if (this.props.data) {
        if (
          StringWebalizer(this.props.data.companyBranchesLookUp.finalAddressFormatted) !==
          this.props.match.params.address
        ) {
          return (
            <HelperRefetchData
              address={this.props.match.params.address}
              deliveryTypeState={this.state.deliveryType}
            />
          )
        } else {
          if (this.props.data.companyBranchesLookUp.resolvedBranches.length > 0) {
            return this.props.data.companyBranchesLookUp.resolvedBranches
              .sort((a, b) => b.companyBranch.isOpen - a.companyBranch.isOpen)
              .map(({ companyBranch, url }, index) =>
                companyBranch.enabled ? (
                  <BranchItem
                    key={index}
                    item={companyBranch}
                    deliveryType={this.state.deliveryType}
                    address={this.props.data.companyBranchesLookUp.finalAddressFormatted}
                  />
                ) : null
              )
          }
        }
      } else {
        return (
          <HelperRefetchData
            address={this.props.match.params.address}
            deliveryTypeState={this.state.deliveryType}
          />
        )
      }
    } else {
      return this.props.data.companyBranchesLookUp.resolvedBranches
        .sort((a, b) => b.companyBranch.isOpen - a.companyBranch.isOpen)
        .map(({ companyBranch, url }, index) =>
          companyBranch.enabled ? (
            <BranchItem
              key={index}
              item={companyBranch}
              deliveryType={this.state.deliveryType}
              address={this.props.data.companyBranchesLookUp.finalAddressFormatted}
            />
          ) : null
        )
    }
    this.setState({
      reqForm: false,
    })
  }

  render() {
    return (
      <Translation>
        {(t) => (
          <Fragment>
            {this.generateTitle()}
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <div className="OrderPanel">
                        <ChangeAddressForm
                          data={this.props.data}
                          click={() => this.setState({ reqForm: true })}
                        />
                        <form className={"deliveryType"}>
                          <div>
                            <label
                              htmlFor="deliver_doveze"
                              className={this.state.deliveryType ? "active" : ""}
                            >
                              <input
                                type="radio"
                                name={"deliveryType"}
                                id={"deliver_doveze"}
                                onClick={() => this.deliveryType()}
                                disabled={this.state.deliveryType}
                                checked={this.state.deliveryType}
                                readOnly={true}
                              />
                              <FontAwesomeIcon icon={"truck"} /> {t("we_will_deliver")}
                            </label>
                          </div>
                          <div>
                            <label
                              htmlFor="deliver_pickup"
                              className={!this.state.deliveryType ? "active" : ""}
                            >
                              <input
                                type="radio"
                                name={"deliveryType"}
                                id={"deliver_pickup"}
                                onClick={() => this.deliveryType()}
                                disabled={!this.state.deliveryType}
                                checked={!this.state.deliveryType}
                                readOnly={true}
                              />
                              <FontAwesomeIcon icon={"walking"} /> {t("i_will_pickup")}
                            </label>
                          </div>
                        </form>
                  </div>
                </div>
              </div>
              <div className="row justify-content-center pb-5">{this._dataRender()}</div>
            </div>
            {this.props.branches.loading ? <LoadingOverlay /> : null}
          </Fragment>
        )}
      </Translation>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    data: state.branches.data,
    branches: state.branches,
  }
}

const mapStateToDispatch = (dispatch) => {
  return {
    branchStart: () => dispatch(actionFetchBranchesStart()),
    branchStop: (data) => dispatch(actionFetchBranchesDone(data)),
    branchError: (error) => dispatch(actionFetchBranchesError(error)),
  }
}

export default connect(mapStateToProps, mapStateToDispatch)(RestaurantList)
