import React from "react";
import PropTypes from "prop-types";

const FoodItem = ({item}) => {

    return (
        <div className={"FoodItem"}>
            {item.image &&
                <div className={"FoodItem_image"}>
                    <img src={item.image} alt={"Obrázek produktu"} draggable={"false"}/>
                </div>
            }
            <div className="FoodItem_content">
                <p className={"FoodItem_name"}>{item.nameLabel}</p>
                {item.descriptionLabel.length > 1 &&
                    <div className={"FoodItem_ingredients"}>{item.descriptionLabel}</div>
                }
            </div>
            <div className="FoodItem_price">
                <p>{item.prices[0].price.formattedValue}</p>
            </div>
        </div>
    )
};

FoodItem.propTypes = {
    item: PropTypes.object.isRequired
};

export default FoodItem;