import React, { Fragment } from 'react';

import { Link } from 'react-router-dom';

const PersonalInformationCZ = () => {
  return (
    <Fragment>
      <h2>1. Kdo jsme a jak nás můžete kontaktovat?</h2>
      <p>
        V kontextu Vašeho využívání našich služeb objednávky jídel
        prostřednictvím speciálně zřízeného portálu, případně prostřednictvím
        aplikace dostupné v AppStore a GooglePlay (obojí společně dále jen jako
        „Portál“) jsme my, společnost [název subjektu/firmy, která uskutečňuje
        prodej] (dále jen „my“), správcem Vašich osobních údajů. Správcem
        osobních údajů je ten, kdo určuje účely a prostředky zpracování, tedy
        ten, kdo rozhoduje z jakého důvodu a jakým způsobem jsou osobní údaje
        zpracovávány.
      </p>
      <p>
        Níže uvádíme kontaktní údaje pro případ, že v tomto dokumentu nenajdete
        odpovědi na své otázky nebo nebudete některým z uvedených informací
        rozumět.
      </p>
      <p>
        E-mail zpracovatele:{" "}
        <a href="mailto:info@speedlo.cz">info@speedlo.cz</a>
      </p>
      <p>Pošta zpracovatele: Hlaváčova 207, 530 02 Pardubice</p>
      <p>
        Portál spravuje společnost speedlo s.r.o., IČO: 08750866, se sídlem
        Hlaváčova 207, Zelené Předměstí, 530 02 Pardubice, která jej pro nás
        vyvinula, a která k Vašim osobním údajům v souvislosti s objednávkou
        vystupuje jako zpracovatel osobních údajů. V některých případech však
        může vystupovat jako správce osobních údajů, přičemž v takovém případě
        se zpracování osobních údajů řídí jejich zásadami, které jsou dostupné{" "}
        <Link to="/zasady-ochrany-osobnich-udaju">zde:</Link>{" "}
        (https://speedlo.cz/zasady-ochrany-osobnich-udaju)
      </p>
      <hr />
      <h2>2. Jaké osobní údaje zpracováváme a jak je získáváme?</h2>
      <p>
        Vaše osobní údaje získáváme primárně od Vás a to následujícími způsoby:
      </p>
      <ul>
        <li>
          <h3>Při objednání jídla z restaurace</h3>
          <p>
            Abyste prostřednictvím našeho Portálu mohli udělat objednávku,
            budeme potřebovat Vaše kontaktní údaje, především Vaše jméno,
            e-mail, telefonní číslo a kontaktní adresu. Dále budeme potřebovat
            informaci o tom, jakým způsobem svou objednávku zaplatíte. Pokaždé,
            když prostřednictvím našeho Portálu nakoupíte, zaznamenáváme
            informace týkající nákupu (tj. historii nákupů, informace o
            objednaném jídle, informace o místě doručení).
          </p>
          <p>
            V případě, že zvolíte platbu prostřednictvím platební brány, budeme
            k tomuto zpracování zadaných informací využívat poskytovatele
            platební brány a společnost speedlo, která technicky zajišťuje
            zřízení platební brány.
          </p>
        </li>
        <li>
          <h3>Při využívání našich služeb</h3>
          <p>
            Při používání našich služeb mohou být určité informace shromažďovány
            pasivně. Jedná se především o IP adresu, typ prohlížeče a druh
            operačního systému. Používáme také soubory cookies a navigační data,
            abychom shromáždili informace týkající se data a času Vaší návštěvy
            a informací, které jste vyhledávali a zobrazovali. Pokud využíváte
            naše mobilní aplikace, můžeme získat také informace o tom, jak na
            Vašem zařízení využíváte naše služby, a to včetně informace o Vaší
            poloze, pokud jste na Vašem mobilním zařízení tuto funkci povolili.
          </p>
        </li>
      </ul>
      <hr />
      <h2>3. Pro jaké účely zpracováváme Vaše osobní údaje?</h2>
      <h3>Přístup k naší Platformě</h3>
      <p>
        Vaše osobní údaje zpracováváme, abychom Vám umožnili přístup k našim
        poskytovaným službám (Platformě) a Vy jste si u nás mohli udělat
        objednávku.
      </p>
      <p>
        Pro uvedený účel zpracováváme osobní údaje na základě právního titulu
        splnění smlouvy.
      </p>
      <h3>Administrace Vaší objednávky</h3>
      <p>
        Poté, co si prostřednictvím naší Platformy uděláte objednávku,
        potřebujeme ji u nás zprocesovat. Také Vás buď my nebo zpracovatel
        (kterého jsme pověřili) můžeme kontaktovat ohledně Vašeho nákupu – např.
        abyste dostali aktualizaci o stavu Vaší objednávky (e-mailem,
        telefonicky, SMS zprávou nebo prostřednictvím naší mobilní aplikace).
      </p>
      <p>
        Pro uvedený účel zpracováváme osobní údaje na základě právního titulu
        splnění smlouvy.
      </p>
      <h3>Správa a zlepšování našich služeb</h3>
      <p>
        Vaše údaje nám slouží také k tomu, abychom mohli, mimo jiné díky jejich
        analýze, spravovat, vylepšovat a rozvíjet naše služby. Dále můžeme Vaše
        osobní údaje využívat k tomu, abychom vyřešili jakékoliv případné
        nesrovnalosti a chyby, případně Vaše stížnosti.
      </p>
      <p>
        Pro uvedené účely zpracováváme Vaše osobní údaje na základě oprávněného
        zájmu (který spočívá ve zlepšování našich služeb).
      </p>
      <h3>Prevence podvodného jednání a plnění právních povinností</h3>
      <p>
        Jako restaurační zařízení musíme ukládat Vaše osobní údaje, abychom
        splnili právní povinnosti, zejména povinnosti vyplývající z účetních a
        daňových předpisů. Zároveň v případě sporu můžeme využít Vaše osobní
        údaje k tomu, abychom chránili naše zájmy a využili je v případných
        soudních a jiných řízeních.
      </p>
      <p>
        Pro uvedený účel zpracováváme osobní údaje na základě právního titulu
        oprávněného zájmu (který spočívá v ochraně našich vlastních zájmů) a na
        základě právního titulu plnění právní povinnosti.
      </p>
      <h3>Zpětná vazba a důležité změny</h3>
      <p>
        Vaše osobní údaje využíváme také proto, abychom od Vás získali zpětnou
        vazbu k poskytovaným službám. Čas od času Vás také upozorníme na
        důležité změny týkající se námi poskytovaných služeb.
      </p>
      <p>
        Pro uvedené účely zpracováváme osobní údaje na základě právního titulu
        oprávněného zájmu, který spočívá ve zlepšování námi poskytovaných
        služeb.
      </p>
      <h3>Personalizace našich služeb</h3>
      <p>
        Rádi bychom, aby to, co Vám nabízíme prostřednictvím našeho Portálu a v
        souvislosti s ním, co nejvíce odpovídalo tomu, co Vás skutečně zajímá.
        Vaše osobní údaje tak můžeme použít k tomu, abychom to lépe pochopili,
        nebo se o to alespoň pokusili. To nám také umožňuje přizpůsobit naši
        komunikaci vůči Vám tak, aby pro Vás byla co možná nejvíce relevantní a
        zajímavá.
      </p>
      <p>
        Pro uvedený účel zpracováváme osobní údaje na základě právního titulu
        oprávněného zájmu, který spočívá v přizpůsobení námi poskytovaných
        služeb.
      </p>
      <h3>Marketingová sdělení</h3>
      <p>
        Vaše kontaktní údaje využíváme také k tomu, abychom Vám mohli zasílat
        informace o nabídkách, které by Vás mohly zajímat, případně Vám posílali
        různé zajímavé slevové kupóny. Za tím účelem Vás budeme kontaktovat
        především prostřednictvím e-mailu, SMS, případně prostřednictvím naší
        mobilní aplikace.
      </p>
      <p>
        Pro uvedené účely zpracováváme osobní údaje na základě právního titulu
        oprávněného zájmu, který spočívá v přímém marketingu. Případně můžeme
        pro tato marketingová sdělení vyžadovat souhlas. Právní titul pro
        zpracování poznáte tak, zda vám při dokončení objednávky dáme na výběr
        buďto souhlasit se zasíláním marketingových sdělení, nebo možnost
        zasílání marketingových sdělení odmítnout.
      </p>
      <h2>4. Doba, po kterou Vaše osobní údaje zpracováváme a uchováváme</h2>
      <p>
        Vaše osobní údaje zpracováváme a uchováme pouze tak dlouho, jak je
        nezbytné pro účely, které jsou uvedené výše, anebo tak dlouho, abychom
        dostáli zákonným požadavkům. Po uplynutí této doby osobní údaje vymažeme
        nebo anonymizujeme.
      </p>
      <p>
        Pokud zpracováváme Vaše osobní údaje na základě oprávněného zájmu, trvá
        zpracování po dobu, kdy trvá náš oprávněný zájem. Proti zpracování
        Vašich osobních údajů, které probíhá na základě právního titulu
        oprávněného zájmu můžete kdykoliv podat námitku.
      </p>
      <p>
        V případě marketingových sdělení budeme uchovávat Vaše osobní údaje po
        dobu, dokud nám zasílání marketingových sdělení neodmítnete. Způsob
        odmítnutí (respektive právo a způsob, jak odvolat souhlas) je uveden v
        každém jednotlivém marketingovém sdělení.
      </p>
      <hr />
      <h2>5. Komu Vaše osobní údaje zpřístupňujeme?</h2>
      <p>Vaše osobní údaje sdílíme s následujícími příjemci:</p>
      <ul>
        <li>
          <p>
            se společností speedlo s.r.o., IČO: 08750866, se sídlem Hlaváčova
            207, Zelené Předměstí, 530 02 Pardubice, která pro nás vyvinula
            Portál, a která k Vašim osobním údajům v souvislosti s objednávkou
            vystupuje jako zpracovatel osobních údajů a může zajišťovat další
            činnosti, jako uložení osobních údajů, marketingové akce, pokladní
            systém aj.;
          </p>
        </li>
        <li>
          <p>
            v závislosti na platební metodě, kterou zvolíte v rámci Vaší
            objednávky, v některých případech sdílíme Vaše údaje s
            poskytovatelem této platební metody; tak je tomu například, když se
            rozhodnete provést platbu přes GOPAY (Vaše osobní údaje jsou předány
            společnosti GOPAY s.r.o., IČO: 260 46 768, se sídlem Planá 67, 370
            01 Planá); případně můžete využít platební bránu ČSOB (osobní údaje
            jsou předány společnosti Československá obchodní banka, a.s., IČO:
            00001350, se sídlem Praha 5, Radlická 333/150, PSČ 15057) pokud se
            rozhodnete provést platbu platební kartou, Vaše interakce probíhá s
            příslušným provozovatelem platební brány a se společností speedlo,
            která pomáhá s technickým zajištěním plateb;
          </p>
        </li>
        <li>
          <p>
            s našimi dodavateli, především s osobami, které zajišťují dopravu
            Vašich objednávek, dále pak také s dodavateli IT, hostingových a
            marketingových služeb, s poskytovateli statistických, databázových a
            jiných softwarových nástrojů, a případně s jinými subjekty, které
            nám poskytují své služby.
          </p>
        </li>
      </ul>
      <p>
        Na základě právních předpisů můžeme být za určitých předpokladů povinni
        Vaše osobní údaje sdílet bez Vašeho souhlasu se třetími osobami
        (například s orgány činnými v trestním řízení atp.). Jedná se o naši
        zákonnou povinnost.
      </p>
      <hr />
      <h2>
        6. Jakým způsobem zpracováváme Vaše osobní údaje a jak jsou zabezpečeny?
      </h2>
      <p>
        Zpracování osobních údajů probíhá manuálně i automatizovaně v
        elektronických informačních systémech, v elektronické nebo listinné
        podobě. Vaše osobní údaje jsou zpracovávány především našimi zaměstnanci
        a zpracovateli. Přijali jsme vhodná organizační a bezpečnostní opatření,
        abychom ochránili Vaše osobní údaje před jakoukoli náhodnou ztrátou,
        zničením, zneužitím, poškozením a neoprávněným nebo nezákonným
        přístupem. Se zpracovateli, kteří pro nás jako pro správce zpracovávají
        Vaše osobní údaje, jsme uzavřeli relevantní smlouvy tak, aby byla
        zaručena adekvátní ochrana Vašich osobních údajů. Zaměstnanci a
        zpracovatelé, kteří se podílejí na zpracování Vašich osobních údajů,
        jsou vázáni mlčenlivostí.
      </p>
      <hr />
      <h2>7. Jaká jsou Vaše práva v oblasti ochrany osobních údajů?</h2>
      <p>
        Kdykoliv během zpracování Vašich osobních údajů můžete využít
        následujících práv, která můžete uplatnit například prostřednictvím
        e-mailové adresy uvedené výše:
      </p>
      <ul>
        <li>
          <h3>Právo na přístup.</h3>
          <p>
            Máte právo získat potvrzení o tom, zda a jaké Vaše osobní údaje
            zpracováváme včetně práva na kopie osobních údajů, které
            zpracováváme. Berte prosím v úvahu, že nebude možné zpřístupnění
            takových informací, které by ohrozily naše obchodní tajemství nebo
            práva třetích osob.
          </p>
        </li>
        <li>
          <h3>Právo na opravu a doplnění.</h3>
          <p>
            Pokud zjistíte, že jsou Vaše osobní údaje, které zpracováváme,
            nepřesné nebo neúplné, máte právo nás požádat o jejich opravu a
            aktualizaci.
          </p>
        </li>
        <li>
          <h3>Právo na výmaz.</h3>
          <p>
            V některých případech nás máte právo oslovit s žádostí o vymazání
            Vašich osobních údajů, které zpracováváme. Berte prosím v úvahu, že
            výmaz osobních údajů není možné provést vždy, zejména ne tehdy,
            pokud zpracování probíhá pro legitimní účel a v souladu s předpisy
            na ochranu osobních údajů.
          </p>
        </li>
        <li>
          <h3>Právo na omezení zpracování.</h3>
          <p>
            V některých případech, např. v případě, kdy zpochybňujete přesnost,
            zákonnost nebo naši potřebu zpracovávat Vaše osobní údaje, máte
            právo nás požádat o omezení zpracování Vašich osobních údajů. Pokud
            Vaší žádosti vyhovíme, omezíme zpracování osobních údajů na nezbytné
            minimum (a údaje zpravidla nebudeme dále aktivně zpracovávat). V
            našich systémech zaznamenáme, že se na dané údaje vztahuje omezení.
            Pokud dojde ke zrušení omezení a my budeme mít v úmyslu ve
            zpracovávání Vašich osobních údajů pokračovat, budeme Vás o tom
            předem informovat.
          </p>
        </li>
        <li>
          <h3>Právo na přenositelnost.</h3>
          <p>
            Máte právo požadovat, abychom Vaše osobní údaje, které jste nám
            poskytli a které zpracováváme, předali (a to v běžně používaném a
            strojově čitelném formátu) Vám či třetí straně (jinému správci
            údajů), kterého uvedete ve Vaší žádosti. Berte prosím v úvahu, že
            toto své právo můžete uplatnit, pouze pokud zpracováváme Vaše osobní
            údaje na základě Vašeho souhlasu nebo pro účely plnění smlouvy a
            zároveň je zpracování automatizované.
          </p>
        </li>
        <li>
          <h3>Právo vznést námitku</h3>
          <p>
            Můžete vznést námitku proti zpracování Vašich osobních údajů, které
            zpracováváme pro účely přímého marketingu nebo z důvodu oprávněného
            zájmu. Pokud vznesete námitku proti zpracování pro účely přímého
            marketingu, nebudou již Vaše osobní údaje pro tyto účely
            zpracovávány. V případě námitky proti zpracování, založeného na
            dalších důvodech, bude tato námitka vyhodnocena a následně Vám
            sdělíme, zda jsme jí vyhověli a Vaše údaje nebudeme nadále
            zpracovávat, nebo že námitka nebyla důvodná a zpracování bude
            pokračovat. Každopádně po dobu, než bude námitka vyřešena, bude
            zpracování omezeno.
          </p>
        </li>
        <li>
          <h3>Právo podat stížnost u dozorového orgánu.</h3>
          <p>
            Pokud se domníváte, že došlo k porušení povinností stanovených
            právními předpisy na ochranu osobních údajů, máte právo podat
            stížnost u Úřadu pro ochranu osobních údajů nebo u jiného úřadu
            členského státu Evropské unie, který je pověřen dozorem nad
            dodržováním povinností v oblasti ochrany osobních údajů. Záleží nám
            na Vás, budeme proto velmi rádi, pokud se před případným podáním
            stížnosti obrátíte přímo na nás a dáte nám tak příležitost vše
            vyřešit přímo s Vámi.
          </p>
        </li>
      </ul>
    </Fragment>
  );
};

export default PersonalInformationCZ;
