import React, { Component } from "react"

import { Translation } from "react-i18next"
import { Link } from "react-router-dom"

class Footer extends Component {
  componentDidMount() {
    window.scrollTo(0, 0)
  }

  render() {
    return (
      <Translation>
        {(t) => (
          <footer>
            <div className="container">
              <div className="row">
                <div className="col-lg-3 col-sm-6">
                  <div className="footer_list_links">
                    <p>{t("usefull_links")}</p>
                    <ul>
                      <li>
                        <Link to="/kontakt">{t("contact")}</Link>
                      </li>
                      <li>
                        <a
                          href="https://speedlopro.cz/"
                          target={"_blank"}
                          rel={"noopener noreferrer"}
                        >
                          {t("im_interested_in_speedlo")}
                        </a>
                      </li>
                      <li>
                        <Link to="/obchodni-podminky">{t("terms")}</Link>
                      </li>
                      <li>
                        <Link to="/zasady-ochrany-osobnich-udaju">{t("privacy_policy")}</Link>
                      </li>
                      <li>
                        <button
                          id="open-cookie-settings"
                          data-cc="c-settings"
                          aria-haspopup="dialog"
                        >
                          {t("edit_cookie_consent")}
                        </button>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-lg-3 col-sm-6">
                  <div className="footer_list_links">
                    <p>{t("our_projects")}</p>
                    <ul>
                      <li>
                        <a
                          href="https://proc.speedlo.cz/"
                          target={"_blank"}
                          rel={"noopener noreferrer"}
                        >
                          speedlo
                        </a>
                      </li>
                      <li>
                        <a
                          href="http://speedlowaiter.cz/"
                          target={"_blank"}
                          rel={"noopener noreferrer"}
                        >
                          speedlowaiter
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://play.google.com/store/apps/developer?id=DEEP+VISION+s.r.o."
                          target={"_blank"}
                          rel={"noopener noreferrer"}
                        >
                          {t("mobile_app")}
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-lg-3 col-sm-6">
                  <div className="footer_list_links footer_social_icons">
                    <p>{t("stay_in_touch")}</p>
                    <ul>
                      <li>
                        <a
                          href="https://www.facebook.com/speedlo.cz/"
                          target={"_blank"}
                          rel={"noopener noreferrer"}
                        >
                          <img
                            src="/portal_static/img/icons/facebook.png"
                            alt="Facebook"
                            className={"img-fluid"}
                            draggable={false}
                          />
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://www.instagram.com/speedlo.cz/"
                          target={"_blank"}
                          rel={"noopener noreferrer"}
                        >
                          <img
                            src="/portal_static/img/icons/instagram.png"
                            alt="Instagram"
                            className={"img-fluid"}
                            draggable={false}
                          />
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://www.linkedin.com/company/speedlopro"
                          target={"_blank"}
                          rel={"noopener noreferrer"}
                        >
                          <img
                            src="/portal_static/img/icons/linkedin.png"
                            alt="LinkedIn"
                            className={"img-fluid"}
                            draggable={false}
                          />
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-lg-12">
                  <div className="footer_payment">
                    <img
                      src="/portal_static/img/icons/visa.png"
                      alt="Visa"
                      className={"img-fluid"}
                      draggable={false}
                    />
                    <img
                      src="/portal_static/img/icons/mastercard.png"
                      alt="MasterCard"
                      className={"img-fluid"}
                      draggable={false}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="container">
              <div className="footer_copyright">
                <p>{t("baked_in")} speedlo s.r.o.</p>
                <p>
                  Illustration by{" "}
                  <a href="https://storyset.com/" target={"_blank"}>
                    Storyset
                  </a>
                </p>
              </div>
            </div>
          </footer>
        )}
      </Translation>
    )
  }
}

export default Footer
