import React, { Fragment, useState } from "react"

import { Translation, withTranslation } from "react-i18next"

import CrossroadSection from "../Components/CrossroadSection"
import SearchForm from "../Components/SearchForm"
import Usp from "../Components/Usp"

const Homepage = () => {
  const [showCrossroadSection, setShowCrossroadSection] = useState(false)

  // check when crossRoadSection was last opened (date and time in milliseconds)
  // return true if it is more then one our (show crossroad section)
  // return false if it is less than one our (don't show crossroad section)
  React.useEffect(() => {
    const shouldShowCrossroadSection = () => {
      const lastOpenTime = localStorage.getItem(`crossroadLastOpenTime`)

      if (!lastOpenTime) {
        return true
      }

      const currentTimeMillis = new Date().getTime()
      const lastOpenTimeMillis = parseInt(lastOpenTime)
      const oneHourInMillis = 60 * 60 * 1000

      return currentTimeMillis - lastOpenTimeMillis >= oneHourInMillis
    }

    if (shouldShowCrossroadSection()) {
      setShowCrossroadSection(true)
    } else {
      setShowCrossroadSection(false)
    }
  }, [])

  return (
    <Translation>
      {(t) => (
        <Fragment>
          {/* Zobrazení CrossroadSection */}
          {showCrossroadSection && (
            <CrossroadSection onClose={() => setShowCrossroadSection(false)} />
          )}
          {/* Zbytek obsahu stránky */}
          {!showCrossroadSection && (
            <Fragment>
              <header className={"homepage-header"}>
                <div className={"container"}>
                  <div className={"header-grid"}>
                    <div className={"header-content"}>
                      <p className={"headline"}>
                        {t("are_you_hungry")}{" "}
                        <span className={"color-primary"}>{t("choose_restaurant")}</span>
                      </p>
                      <SearchForm />
                    </div>
                  </div>
                </div>
                <div className={"header-image"}></div>
              </header>
              <section id="usp">
                <div className="container">
                  <div className="UspGrid">
                    <Usp
                      name={t("enter_the_address")}
                      text={t("enter_the_exact_address_and_pick_food")}
                      icon={"../portal_static/img/illustration/address.png"}
                    />
                    <Usp
                      name={t("choose_a_food")}
                      text={t("choose_a_food_and_we_deliver")}
                      icon={"../portal_static/img/illustration/fast-food.png"}
                    />
                    <Usp
                      name={t("enter_your_details")}
                      text={t("we_let_you_know")}
                      icon={"../portal_static/img/illustration/contact.png"}
                    />
                    <Usp
                      name={t("enjoy_your_meal")}
                      text={t("then_u_can_eat")}
                      icon={"../portal_static/img/illustration/complete-food.png"}
                    />
                  </div>
                </div>
              </section>
            </Fragment>
          )}
        </Fragment>
      )}
    </Translation>
  )
}

export default withTranslation()(Homepage)
