import React, {Component, Fragment} from "react";
import {ApiAxios} from "../../../axiosConfig";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {ApolloConsumer} from "react-apollo";
import cogoToast from "cogo-toast";
import GET_BRANCHES_DISCOUNTS from "../../../GqlQueries/GET_BRANCHES_DISCNOUNTS";
import BranchItem from "../../Components/BranchItem";
import {StringWebalizer} from "../../Components/StringWebalizer";
import LoadingOverlay from "../../Components/LoadingOverlay";
import NotFound from "../Static/NotFound";
import {Translation} from 'react-i18next';

class EventDetail extends Component {

    constructor(props) {
        super(props);
        this.state = {
            urlParam: null,
            event: null,
            status: false,
            loading: false,
            branchesData: null,
            finalAddress: null,
            searchInputValue: ''
        }
    }

    componentDidMount() {
        const {match: {params}} = this.props;
        this.getData(params.urlname);
        if (localStorage.getItem("lastAddress") !== null) {
            this.setState({
                searchInputValue: localStorage.getItem("lastAddress")
            })
        }
    }

    handleChange = (value) => {
        this.setState({
            searchInputValue: value
        });
    };

    changeAddress = () => {
        this.setState({
            branchesData: null
        })
    };


    getData = async (param) => {
        this.setState({
            loading: true
        });
        await ApiAxios.get(`/v1/event/${param}`)
            .then((res) => {
                this.setState({
                    event: res.data.data[0],
                    status: res.data.result,
                });
            }).catch((err) => {
                console.error(`Chyba: ${err}`)
            });
        this.setState({
            loading: false
        });
    };

    render() {
        return (
            <Translation>
                {t =>
                    <Fragment>
                        {this.state.loading ?
                            <LoadingOverlay/>
                            :
                            <Fragment>
                                {this.state.status ?
                                    <div className={"EventDetail"} id={StringWebalizer(this.state.event.name)}>
                                        <div className="container">
                                            <div className="row">
                                                <div className="col-lg-12">
                                                    <h2>{this.state.event.name}</h2>
                                                    <div
                                                        dangerouslySetInnerHTML={{__html: this.state.event.description}}/>
                                                    {!this.state.branchesData ?
                                                        <form id={"search-restaurant"}>
                                                            <div className="input-group">
                                                                <input type="text"
                                                                       className="form-control"
                                                                       placeholder={t('try_delivery')}
                                                                       autoComplete="off"
                                                                       id="address_to_delivery"
                                                                       value={this.state.searchInputValue}
                                                                       onChange={e => this.handleChange(e.target.value)}
                                                                       autoFocus={true}
                                                                />
                                                                <div className="map_pointer">
                                                                    <FontAwesomeIcon icon={"map-marker-alt"}/>
                                                                </div>
                                                                <div className="input-group-append">
                                                                    <ApolloConsumer>
                                                                        {(client) => (
                                                                            <button
                                                                                className="btn btn-outline-secondary"
                                                                                type="submit"
                                                                                name="sendTicketRequest"
                                                                                onClick={async (e) => {
                                                                                    e.preventDefault();
                                                                                    if (this.state.searchInputValue === null || this.state.searchInputValue.length <= 0) {
                                                                                        cogoToast.warn(t('location_delivery'));
                                                                                        return
                                                                                    }

                                                                                    try {
                                                                                        const {loading, data, error} = await client.query({
                                                                                            query: GET_BRANCHES_DISCOUNTS,
                                                                                            variables: {
                                                                                                addressSearch: this.state.searchInputValue,
                                                                                                cbArrayIds: this.state.event.branches
                                                                                            }
                                                                                        });

                                                                                        if (loading) {
                                                                                            console.log("Loading.")
                                                                                        } else if (error) {
                                                                                            console.log("Chyba: " + error)
                                                                                        } else {
                                                                                            if (data.companyBranchesLookUp.isValidAddress) {
                                                                                                if (data.companyBranchesLookUp.resolvedBranches.length !== 0) {
                                                                                                    this.setState({
                                                                                                        finalAddress: data.companyBranchesLookUp.finalAddressFormatted,
                                                                                                        branchesData: data.companyBranchesLookUp.resolvedBranches
                                                                                                    });
                                                                                                    console.log(data);
                                                                                                } else {
                                                                                                    cogoToast.error(t('error_delivery_not_in_range'));
                                                                                                }
                                                                                            } else {
                                                                                                cogoToast.error(t('error_unknown_address'));
                                                                                            }
                                                                                        }
                                                                                    } catch (error) {
                                                                                        cogoToast.error(t('error_something_went_wrong'));
                                                                                    }
                                                                                }
                                                                                }>
                                                                                <span>{t('search_restaurants')}</span>
                                                                            </button>
                                                                        )}
                                                                    </ApolloConsumer>
                                                                </div>
                                                            </div>
                                                        </form>
                                                        :
                                                        <div className="row justify-content-center">
                                                            {this.state.branchesData.length > 0 ?
                                                                <Fragment>
                                                                    <div className="col-lg-12">
                                                                        <div className="discount-panel">
                                                                            {t('we_will_deliver_to')} <strong>{this.state.finalAddress}</strong>
                                                                            <button className="change-address-btn ml-2"
                                                                                    onClick={() => this.changeAddress()}>{t('change_address')}
                                                                            </button>
                                                                        </div>
                                                                    </div>
                                                                    <Fragment>
                                                                        {this.state.branchesData.map(({companyBranch, url}, index) => {
                                                                            return (
                                                                                companyBranch.enabled && (companyBranch.willOpenAt !== null || companyBranch.willCloseAt !== null) ?
                                                                                    <BranchItem
                                                                                        key={index}
                                                                                        item={companyBranch}
                                                                                        deliveryType={true}
                                                                                        address={this.state.searchInputValue}
                                                                                        grid={true}
                                                                                        origin={"me0"}
                                                                                    />
                                                                                    :
                                                                                    null
                                                                            )
                                                                        })}
                                                                    </Fragment>
                                                                </Fragment> : null}
                                                        </div>}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    :
                                    <NotFound/>
                                }
                            </Fragment>
                        }
                    </Fragment>
                }
            </Translation>
        )
    }
}

export default EventDetail;