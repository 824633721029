import React from "react";
import useScrollSpy from 'react-use-scrollspy';
import PropTypes from "prop-types";
import FoodItem from "./FoodItem";

const FoodList = ({data}) => {

    const refs = data.filter(item => item.wareCategoryType.enum === "MAIN")
        .map((acc, index) => {
            acc[index] = React.createRef();
            return acc;
        });

    const handleClick = id => {
        refs[id].current.scrollIntoView({
            behavior: 'smooth',
            block: 'start',
        });
    };

    const activeSection = useScrollSpy({
        sectionElementRefs: refs,
        offsetPx: -5,
    });

    // Fetchs
    const fetchCategories = () => {
        let categories = [];
        data.filter(item => item.wareCategoryType.enum === "MAIN")
            .map((item, index) => {
                return categories.push(
                    <li className={"FoodList_category" + (activeSection === index ? " active" : "")} key={index} onClick={() => handleClick(index)}>{item.nameLabel}</li>
                )
            });
        return categories;
    };

    const fetchMenu = () => {
        let menu = [];
        data.filter(item => item.wareCategoryType.enum === "MAIN")
            .map((item, index) => {
                return menu.push(
                    <div className={"category_wrapper"} key={index} ref={refs[index]}>
                        <h2 key={index}>{item.nameLabel}</h2>
                        <div className={"category_wrapper_category shadow-primary"}>
                            {item.recipes
                                .filter(fooditem => fooditem.enabled)
                                .map((fooditem, iid) => {
                                    return (
                                        <FoodItem item={fooditem} key={index + '-' + iid}/>
                                    )
                                })}
                        </div>
                    </div>
                );
            });
        return menu;
    };

    return (
        <div className={"FoodList"}>
            <div className="row">
                <div className="col-lg-3">
                    <div className="FoodList_categories shadow-primary sticky-top">
                        <ul>
                            {fetchCategories()}
                        </ul>
                    </div>
                </div>
                <div className="col-lg-9">
                    <div className="FoodList_menu">
                        {fetchMenu()}
                    </div>
                </div>
            </div>
        </div>
    )
};

FoodList.propTypes = {
    data: PropTypes.array.isRequired
};

export default FoodList;