import React, { Fragment } from "react"

import cogoToast from "cogo-toast"
import { ApolloConsumer } from "react-apollo"
import { Translation } from "react-i18next"
import { connect } from "react-redux"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

import {
  actionFetchBranchesDone,
  actionFetchBranchesError,
  actionFetchBranchesStart,
  actionLoadUserAddress,
} from "../../actions/branchesAction"
import { GET_BRANCHES } from "../../GqlQueries/GET_BRANCHES"
import { StringWebalizer } from "./StringWebalizer"

class ChangeAddressForm extends React.Component {
  constructor() {
    super()
    this.state = {
      formAddressVal: "",
      formAddress: false,
    }
  }

  componentDidMount() {
    window.scrollTo(0, 0)

    if (localStorage.getItem("lastAddress") !== null) {
      this.setState({
        formAddressVal: localStorage.getItem("lastAddress"),
      })
    }
  }

  onClick = () => {
    this.setState({
      formAddress: !this.state.formAddress,
      formAddressVal: this.props.data.companyBranchesLookUp.finalAddressFormatted,
    })
    this.props.click()
  }

  render() {
    return (
      <Translation>
        {(t) => (
          <Fragment>
            {this.state.formAddress ? (
              <form className={"deliveryChangeForm"}>
                <div className="input-group">
                  <input
                    type="text"
                    value={this.state.formAddressVal}
                    placeholder={t("enter_address")}
                    onChange={(e) => this.setState({ formAddressVal: e.target.value })}
                    className={"form-control"}
                  />
                  <div className="input-group-append">
                    <ApolloConsumer>
                      {(client) => (
                        <button
                          className="search-btn"
                          type="submit"
                          name="sendTicketRequest"
                          onClick={async (e) => {
                            e.preventDefault()
                            if (
                              this.state.formAddressVal === undefined ||
                              this.state.formAddressVal.length <= 0
                            ) {
                              cogoToast.warn(t("location_delivery"))
                              return
                            }

                            try {
                              this.props.branchStart()
                              const { loading, data, error } = await client.query({
                                query: GET_BRANCHES,
                                variables: { addressSearch: this.state.formAddressVal },
                              })

                              if (loading) {
                                this.props.branchStart()
                              } else if (error) {
                                this.props.branchError(error)
                                cogoToast.error(t("error_something_went_wrong"))
                              } else {
                                this.props.branchStop(data)
                                window.history.pushState(
                                  null,
                                  "speedlo",
                                  "/doveze/" +
                                    StringWebalizer(
                                      data.companyBranchesLookUp.finalAddressFormatted
                                    )
                                )
                                if (data.companyBranchesLookUp.isValidAddress) {
                                  if (data.companyBranchesLookUp.resolvedBranches.length !== 0) {
                                    const storage = window.localStorage
                                    storage.setItem(
                                      "lastAddress",
                                      data.companyBranchesLookUp.finalAddressFormatted
                                    )
                                    this.props.branchStop(data)
                                    window.history.replaceState(
                                      null,
                                      "speedlo",
                                      "/doveze/" +
                                        StringWebalizer(
                                          data.companyBranchesLookUp.finalAddressFormatted
                                        )
                                    )
                                  } else {
                                    window.location.replace("/")
                                    cogoToast.error(t("error_delivery_not_in_range"))
                                    this.props.branchStop()
                                  }
                                } else {
                                  window.location.replace("/")
                                  cogoToast.error(t("error_unknown_address"))
                                  this.props.branchStop()
                                }
                              }
                              this.setState({
                                formAddress: !this.state.formAddress,
                              })
                            } catch (error) {
                              console.error(error)
                              cogoToast.error(t("error_something_went_wrong"))
                            }
                          }}
                        >
                          <span>{t("confirm")}</span>
                        </button>
                      )}
                    </ApolloConsumer>
                    <button
                      className="close-btn"
                      onClick={() => this.setState({ formAddress: !this.state.formAddress })}
                    >
                      &times;
                    </button>
                  </div>
                </div>
              </form>
            ) : (
              <div className={"deliveryAddressTo"}>
                <p>
                  <span>{t("we_will_deliver_to")}</span>{" "}
                  {this.props.data && this.props.data.companyBranchesLookUp.finalAddressFormatted}
                </p>
                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                <a href="#" className={"changeDeliveryAddress"} onClick={() => this.onClick()}>
                  <FontAwesomeIcon icon={"pen"} />
                  {t("change_address")}
                </a>
              </div>
            )}
          </Fragment>
        )}
      </Translation>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    data: state.branches.data,
    branches: state.branches,
  }
}

const mapStateToDispatch = (dispatch) => {
  return {
    branchStart: () => dispatch(actionFetchBranchesStart()),
    branchStop: (data) => dispatch(actionFetchBranchesDone(data)),
    branchError: (error) => dispatch(actionFetchBranchesError(error)),
    loadAddress: (address) => dispatch(actionLoadUserAddress(address)),
  }
}

export default connect(mapStateToProps, mapStateToDispatch)(ChangeAddressForm)
