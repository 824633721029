import React, {Component, Fragment} from "react";
import {GET_BRANCH} from "../../../GqlQueries/GET_BRANCH";
import {Query} from "@apollo/react-components";
import LoadingOverlay from "../../Components/LoadingOverlay";
import RestaurantDetailData from "./RestaurantDetailData";
import {Translation} from 'react-i18next';

const BranchQuery = ({bId}) => {
    return (
        <Query
            query={GET_BRANCH}
            variables={{branchId: bId}}
        >
            {({loading, error, data}) => {
                if (loading) return <LoadingOverlay/>;
                if (error) console.log('error');
                return (
                    <Translation>
                        {t =>
                            <Fragment>
                                {data ?
                                    <RestaurantDetailData branchData={data}/>
                                    :
                                    <Fragment>
                                        {t('branch_doesnt_exist')}
                                    </Fragment>
                                }
                            </Fragment>
                        }
                    </Translation>
                );
            }}
        </Query>
    );
};

class RestaurantDetail extends Component {


    componentDidMount() {
        window.scrollTo(0, 0);
    };

    render() {
        return (
            <BranchQuery bId={this.props.match.params.id}/>
        )
    }
}


export default RestaurantDetail;