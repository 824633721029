import { applyMiddleware, createStore } from "redux";
import thunk from "redux-thunk";
import {persistStore, persistReducer} from "redux-persist";
import storage from 'redux-persist/lib/storage';
import rootReducer from "./reducers/index";
import logger from 'redux-logger';


const persistConfig = {
    key: 'root',
    storage,
};

let middlewareEnhancer;
if (process.env.NODE_ENV === 'development') {
    middlewareEnhancer = applyMiddleware(logger, thunk);
} else {
    middlewareEnhancer = applyMiddleware(thunk);
}

const persistedReducer = persistReducer(persistConfig, rootReducer);


export default () => {
    let store = createStore(persistedReducer, middlewareEnhancer);
    let persistor = persistStore(store);
    return { store, persistor };
}