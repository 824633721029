import React, {Fragment} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Base64} from 'js-base64';
import PropTypes from "prop-types";
import moment from 'moment';
import {Translation} from 'react-i18next';

const BranchItem = ({item, deliveryType, origin, address}) => {

    const getUrl = () => {
        let finalAddress = Base64.encode(address);
        const escapedAddress = encodeURIComponent(finalAddress);

        if (deliveryType) {
            if (origin) {
                return item.brand.url.replace("dvz", origin) + "?address=" + escapedAddress
            } else {
                return item.brand.url + "/" + item.urlPath + "?address=" + escapedAddress
            }
        } else {
            if (item.pickupEnabled) {
                return item.brand.url + "/" + item.urlPath + "?pickup"
            }
        }
    };

    const openTimeControl = (openTime, closeTime, t) => {

        let NOW = new Date();
        const OPEN = moment(openTime);
        const ONE_HOUR = 60 * 59 * 1000;
        const TOMORROW = moment(new Date()).add(1, 'days');
        const MORE_DAYS = moment(new Date()).add(2, 'days');

        let OPEN_TIME = new Date(OPEN._d);
        let TIME_RESULT = OPEN_TIME.getTime() - NOW.getTime();

        if (openTime === null && closeTime === null) {
            return <div className='overlay_open_at'>{t('temporarily_closed')}</div>;
        } else if (OPEN_TIME > MORE_DAYS) {
            return <div className='overlay_open_at'>{t('opening')} {OPEN.format("DD.MM. v HH:mm")}</div>;
        } else if (OPEN_TIME.getDate() === TOMORROW._d.getDate()) {
            return <div className='overlay_open_at'>{t('opening_tomorrow_at')} {OPEN.format("HH:mm")}</div>;
        } else {
            if (TIME_RESULT > ONE_HOUR) {
                return <div className='overlay_open_at'>{t('opening_at')} {OPEN.format("HH:mm")}</div>;
            } else {
                return <div className='overlay_open_at'>{t('opening_in')} {moment(TIME_RESULT).add(1, "minute").minutes()} {t('minutes')}</div>;
            }
        }

    };

    const closeTimeControl = (closeTime, t) => {
        const NOW = new Date();
        const CLOSE = moment(closeTime);
        const ONE_HOUR = 60 * 59 * 1000;

        let CLOSE_TIME = new Date(CLOSE._d);
        let TIME_RESULT = CLOSE_TIME.getTime() - NOW.getTime();

        if (TIME_RESULT < ONE_HOUR) {
            return <div className='overlay_close_at'>{t('closing_in')}
                {moment(TIME_RESULT).add(1, "minute").format("m")} {t('minutes')}</div>;
        }
    };

    const renderClosedNote = () => {
        const hasClosedNote = Boolean(item.businessHoursIntervals.length > 0 && item.businessHoursIntervals[0].closedNote);
        const closedNote = hasClosedNote && item.businessHoursIntervals[0].closedNote;
        return (
            <Fragment>
                {hasClosedNote ?
                    <div className={"close_note"}>
                        <FontAwesomeIcon icon="info-circle" className={"mr-1"}/> {closedNote}
                    </div> : ''}
            </Fragment>
        )
    };

    return (
        <Translation>
            {t =>
                <div className={"col-xl-4 col-lg-4 col-md-6 py-3"}>
                    <div
                        className={"BranchItem" + (item.isOpen ? "" : " closed") + (!item.pickupEnabled ? " onlydelivery" : "") + (!item.pickupEnabled && !deliveryType ? " onlydelivery-active" : "")}>
                        <a href={getUrl()}>
                            <div className="BranchItem_image_wrapper">
                                {!item.pickupEnabled ?
                                    <div className="tag_onlydeliver">
                                        {t('delivery_only')}
                                    </div> : null}
                                {!item.isOpen ?
                                    <div className="tag_closed">
                                        {t('closed')}
                                    </div> :
                                    closeTimeControl(item.willCloseAt, t)}
                                {renderClosedNote()}
                                {item.isOpen ? null : openTimeControl(item.willOpenAt, item.willCloseAt, t)}
                                <div className="BranchItem_image">
                                    <img src={item.styles.storeLogo} className={"img-fluid"} draggable={false}
                                         alt="logo" loading="lazy" />
                                </div>
                            </div>
                        </a>
                        <div className="BranchItem_content">
                            <a href={getUrl()}>
                                <div className="BranchItem_content_name">
                                    {item.name}
                                </div>
                            </a>
                            <div className="BranchItem_content_footer">
                                <div className="row">
                                    <div className="col-7">
                                        <a href={getUrl()}>
                                            <div className="BranchItem_content_footer__delivery_time">
                                                {item.defaultDeliveryTimeEnabled && <Fragment>&nbsp;{t('delivery_time')}
                                                    ~ <span>{item.defaultDeliveryTime} min</span></Fragment>}
                                            </div>
                                        </a>
                                    </div>
                                    <div className="col-5 text-right">
                                        <div className="BranchItem_content_footer__apps">
                                            &nbsp;
                                            {item.pwa || item.ios ? <span>PWA</span> : null}
                                            {item.android ?
                                                <a href={item.android.url} className={"text-muted"} target={"_blank"}
                                                   title={"Android"} rel={"noopener noreferrer"}>
                                                    <FontAwesomeIcon icon={["fab", "android"]} className={"ml-1"}/>
                                                </a> : null}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </Translation>
    )
};

BranchItem.propTypes = {
    item: PropTypes.object.isRequired,
    deliveryType: PropTypes.bool.isRequired,
    origin: PropTypes.string,
    address: PropTypes.string.isRequired
};

export default BranchItem;