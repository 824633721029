import React from "react"

const Usp = (props) => {
  return (
    <div className="UspItem">
      <img
        src={props.icon}
        className={"img-fluid"}
        alt={""}
        draggable={false}
        width={"120"}
        height={"120"}
      />
      <p className={"name"}>{props.name}</p>
      <p className={"text"}>{props.text}</p>
    </div>
  )
}

export default Usp
