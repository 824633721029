import React from 'react';
import PropTypes from 'prop-types';

const Tabs = ({data, onSelectTab, selected}) => {

    const _renderList = () => {
        let array = [];

        data.map((item, key) => {
            array.push(
                <div
                    className={"list-group-item list-group-item-action" + (key === selected ? " active" : "")}
                    onClick={() => onSelectTab(key)}
                    key={key}
                >
                    <span>{item.icon && <span className={"icon-before"}>{item.icon} </span>}{item.name}</span>
                </div>
            );
            return array;
        });
        return array;
    };

    return (
        <div className={"row"}>
            <div className="col-lg-12">
                <div className={"Tabs_option"}>
                    {_renderList()}
                </div>
            </div>
            <div className="col-lg-12">
                {data[selected].component}
            </div>
        </div>
    );
};

Tabs.propTypes = {
    onSelectTab: PropTypes.func.isRequired,
    selected: PropTypes.number.isRequired,
    data: PropTypes.array.isRequired,
};

export default Tabs;