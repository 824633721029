export const BRANCHES_FETCH_DONE = 'BRANCHES_FETCH_DONE';
export const BRANCHES_FETCH_ERROR = 'BRANCHES_FETCH_ERROR';
export const BRANCHES_FETCH_START = 'BRANCHES_FETCH_START';
export const BRANCHES_FETCH_LOADED = 'BRANCHES_FETCH_LOADED';

export const actionFetchBranchesStart = () => {
    return {
        type: BRANCHES_FETCH_START
    }
};

export const actionFetchBranchesDone = (branches) => {
    return {
        type: BRANCHES_FETCH_DONE,
        branches
    }
};

export const actionFetchBranchesError = (error) => {
    return {
        type: BRANCHES_FETCH_ERROR,
        error
    }
};

export const actionLoadUserAddress = (address) => {
    return {
        type: BRANCHES_FETCH_LOADED,
        address
    }
};