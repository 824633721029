import React, {Component} from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Translation} from 'react-i18next';

class IosBanner extends Component {
    state = {
        showInstallMessage: false,
    };

    componentDidMount() {
        if (window.navigator.userAgent.indexOf('iPhone') !== -1) {
            if (!window.navigator.standalone) {
                this.setState({showInstallMessage: true});
            }
        }
    }

    render() {
        const {showInstallMessage} = this.state;
        if (!showInstallMessage) return null;

        return (
            <Translation>
                {t =>
                    <div className={"installPopup"}>
                        <div style={{float: "left", marginRight: "10px"}}>
                            <FontAwesomeIcon icon={"plus-square"} size={"2x"}/>
                        </div>
                        <div>
                            {t('install_webapp')} <FontAwesomeIcon
                            icon={"long-arrow-alt-up"}/> {t('and_add_to_desktop')}
                        </div>
                    </div>
                }
            </Translation>
        );
    }
}

export default IosBanner;