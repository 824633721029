import React, { Component } from 'react';

import { Translation } from 'react-i18next';

import ContactForm from '../Components/ContactForm';

class Contact extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <Translation>
        {(t) => (
          <div className={"Contact"}>
            <div className={"container"}>
              <div className="row">
                <div className="col-lg-12">
                  <h2>{t("contact")}</h2>
                </div>
                <div className="col-lg-4">
                  <img
                    className={"Contact_img"}
                    src={"../portal_static/img/icons/headset.png"}
                    alt={""}
                    draggable={false}
                  />
                  <ul>
                    <li>
                      <strong>{t("call_centrum")}</strong>
                    </li>
                    <li>
                      <a href="tel:+420461049231">+420 461 049 231</a>
                    </li>
                    <li>
                      {t("every_day")} <strong>9:00 - 22:00</strong>
                    </li>
                  </ul>
                </div>
                <div className="col-lg-4">
                  <img
                    className={"Contact_img"}
                    src={"../portal_static/img/icons/at.png"}
                    alt={""}
                    draggable={false}
                  />
                  <ul>
                    <li>
                      <strong>{t("contact_mail")}</strong>
                    </li>
                    <li>
                      <a href="mailto:info@speedlo.cz">info@speedlo.cz</a>
                    </li>
                  </ul>
                </div>
                <div className="col-lg-4">
                  <img
                    className={"Contact_img"}
                    src={"../portal_static/img/icons/message.png"}
                    alt={""}
                    draggable={false}
                  />
                  <ul>
                    <li>
                      <strong>{t("contact_address")}</strong>
                    </li>
                    <li>Hlaváčova 207</li>
                    <li>530 02 Pardubice, {t("czech-republic")}</li>
                    <li>IČ: 08750866, DIČ: CZ08750866</li>
                  </ul>
                </div>
                <div className="col-lg-12 mb-5">
                  <p className={"text-center"}>{t("business_register")}</p>
                </div>
                <div className="col-lg-8 offset-lg-2">
                  <ContactForm />
                </div>
              </div>
            </div>
          </div>
        )}
      </Translation>
    );
  }
}

export default Contact;
