import React, {Component, Fragment} from "react";
import {ApiAxios} from '../../../axiosConfig';
import LoadingOverlay from "../../Components/LoadingOverlay";
import {Link} from "react-router-dom";
import {Translation} from 'react-i18next';

class EventList extends Component {

    constructor() {
        super();
        this.state = {
            events: null,
            status: false,
            loading: false
        }
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        this.getData();
    }

    getData = () => {
        this.setState({
            loading: true
        });
        ApiAxios.get('/v1/events')
            .then(res => {
                if (res.data.data != null) {
                    this.setState({
                        events: res.data.data,
                        status: res.data.result,
                    });
                }
                this.setState({loading: false});
            }).catch((err) => {
            console.error(`Chyba: ${err}`);
            this.setState({loading: false});
        });
    };

    _renderData = () => {
        let arr = [];
        this.state.events.map((item, index) => {
            return arr.push(
                <div className={"col-lg-4 col-md-6"} key={index} title={item.name}>
                    <Link to={`/akce/${item.url}`}>
                        <img src={item.image} draggable={false} className={"img-fluid"} alt="logo-akce"/>
                    </Link>
                </div>
            )
        });
        return arr;
    };

    render() {
        return (
            <Translation>
                {t =>
                    <div className={"EventList"}>
                        {!this.state.loading ?
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-12 text-center py-3">
                                        <div className="row">

                                            {this.state.events != null ?
                                                <Fragment>
                                                    <div className="col-lg-12">
                                                        <div className="EventList_banner">
                                                            <h2>{t('speedlo_discounts')}</h2>
                                                            <p>{t('dont_miss_our_discounts')}</p>
                                                        </div>
                                                        <div className="EventList_choose">
                                                            <h2>{t('choose_from_our_discounts')}</h2>
                                                        </div>
                                                    </div>
                                                    {this._renderData()}
                                                </Fragment>
                                                :
                                                <Fragment>
                                                    <div className="col-lg-12 text-center noevents">
                                                        <img src="/portal_static/img/disc.png" className={"img-fluid"}
                                                             draggable={false} alt="404"/>
                                                        <h2>{t('we_dont_have_any_discounts')}</h2>
                                                        <div className="back-to-home">
                                                            <Link className={"primary-btn"} to={"/"}>{t('go_back_home')}</Link>
                                                        </div>
                                                    </div>
                                                </Fragment>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                            :
                            <LoadingOverlay/>
                        }
                    </div>
                }
            </Translation>
        )
    }
}

export default EventList;