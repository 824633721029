import React, { Fragment } from "react"

import { Translation, withTranslation } from "react-i18next"
import { Link } from "react-router-dom"

const CrossroadSection = ({ onClose }) => {
  const handleClick = () => {
    onClose() // Zavřít CrossroadSection a zobrazit zbytek obsahu stránky
    localStorage.setItem("crossroadLastOpenTime", new Date().getTime())
  }

  return (
    <Translation>
      {(t) => (
        <Fragment>
          <section className="crossroad-section">
            <div className="top-content">
              <div className="container">
                <p className="heading">{t("how_can_we_help_you")}</p>
              </div>
            </div>
            <div className="container">
              <div className="crossroad-grid">
                <Link
                  to={"/"}
                  className="option"
                  title={t("i_want_order_food")}
                  onClick={() => handleClick()}
                >
                  <div className="option-content">
                    <div className="option-icon">
                      <img
                        src={"../portal_static/img/illustration/order-food.png"}
                        width={"250"}
                        height={"250"}
                        draggable={false}
                        alt=""
                      />
                    </div>
                    <div className="title">{t("i_want_order_food")}</div>
                    <p className="link">{t("click_to_continue")}</p>
                  </div>
                </Link>

                <a
                  href={"https://speedlopro.cz"}
                  title={t("i_want_order_system")}
                  className="option"
                >
                  <div className="option-content">
                    <div className="option-icon">
                      <img
                        src={"../portal_static/img/illustration/services.png"}
                        width={"250"}
                        height={"250"}
                        draggable={false}
                        alt=""
                      />
                    </div>
                    <div className="title">{t("i_want_order_system")}</div>
                    <p className="link">{t("click_to_continue")}</p>
                  </div>
                </a>
              </div>
            </div>
          </section>
        </Fragment>
      )}
    </Translation>
  )
}

export default withTranslation()(CrossroadSection)
