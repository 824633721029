import React from "react";

const BranchHeader = ({item}) => {

    return (
        <header className={"BranchHeader"}>
            <div className="container">
                <div className="row">
                    <div className="col-lg-4">
                        <img src={item.styles.storeLogo} className={"img-fluid"} draggable={false} alt="item.name"/>
                    </div>
                    <div className="col-lg-8">
                        <h2>{item.name}</h2>
                        <p>{item.headerText}</p>
                    </div>
                </div>
            </div>
        </header>
    )
};

export default BranchHeader;