import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import {initReactI18next} from 'react-i18next';

import lang_cz from "./locales/cz.json";
import lang_en from "./locales/en.json";

i18n
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        resources: {
            cs: {
                translation: lang_cz,
            },
            en: {
                translation: lang_en
            },
        },
        fallbackLng: "cs",
        detection: {
            order: ['cookie'],
            caches: ['cookie'],
            lookupCookie: 'lang',
            cookieMinutes: 20160 // 14 days
        },

        whitelist: ["cs", "en"],
        debug: false,
    });

export default i18n;