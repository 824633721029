import React from "react";

const LoadingOverlay = () => {
    return (
        <div className={"LoadingOverlay"}>
            <div className="LoadingOverlay_content">
                <svg version="1.1" id="loading-logo" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
                     width="521px" viewBox="0 0 521 139" enableBackground="new 0 0 521 139"
                     xmlSpace="preserve">
                    <g>
                        <path fillRule="evenodd" clipRule="evenodd" d="M272.43,108.885c-21.536,0-31.435-13.109-34.377-17.123
		l15.517-8.963c2.274,2.543,7.759,8.563,18.861,8.563c4.014,0,17.658-1.338,20.066-13.377H236.34h-1.9h-56.157
		c2.408,12.039,16.053,13.377,20.066,13.377c11.103,0,16.587-6.02,18.862-8.563l15.517,8.963
		c-2.943,4.014-12.842,17.123-34.378,17.123c-14.313,0-39.997-8.16-39.997-38.392s26.218-38.259,39.997-38.259
		c16.164,0,32.328,9.434,37.041,27.035c4.712-17.601,20.876-27.035,37.04-27.035c13.779,0,39.998,8.027,39.998,38.259
		S286.744,108.885,272.43,108.885L272.43,108.885z M41.512,108.885c19.262,0,30.231-8.695,30.231-23.945
		c0-18.996-16.854-21.136-29.295-23.276c-3.21-0.402-13.912-1.605-13.912-6.555c0-3.612,5.752-5.484,9.631-5.484
		c8.16,0,15.383,3.612,20.868,6.822L69.47,42.802c-9.631-7.224-18.995-10.568-30.633-10.568c-7.089,0-30.365,1.739-30.365,23.276
		c0,18.46,19.129,21.402,29.162,23.142c3.879,0.668,14.179,1.203,14.179,6.152c0,3.746-4.415,6.555-10.568,6.555
		c-8.829,0-18.059-4.012-23.811-7.893L7,97.379C15.026,103.801,25.728,108.885,41.512,108.885L41.512,108.885z M471.75,49.758
		c11.371,0,20.199,8.963,20.199,20.735c0,11.904-8.828,21.001-20.199,21.001c-11.504,0-20.199-8.83-20.199-21.001
		C451.551,58.854,460.514,49.758,471.75,49.758L471.75,49.758z M471.75,108.885c21.938,0,40.131-17.123,40.131-38.392
		c0-21.002-18.193-38.259-40.131-38.259c-21.939,0-39.863,17.256-39.863,38.259C431.887,91.762,449.811,108.885,471.75,108.885
		L471.75,108.885z M405.896,107.145h19.797V6l-19.797,10.724V107.145L405.896,107.145z M94.289,70.626
		c0-12.842,7.759-20.734,20.199-20.734c12.307,0,20.199,9.096,20.199,20.734c0,12.038-7.491,20.866-20.199,20.866
		C102.048,91.492,94.289,82.799,94.289,70.626L94.289,70.626z M95.092,33.839H75.294l0,101.146l19.797-10.725V99.787
		c3.745,4.949,11.638,9.23,22.339,9.23c23.276,0,36.92-17.256,36.92-38.392s-13.644-38.392-36.92-38.392
		c-10.434,0-19.262,4.816-22.339,8.962V33.839L95.092,33.839z M376.979,70.358c0,12.841-7.758,20.734-20.197,20.734
		c-12.309,0-20.199-9.096-20.199-20.734c0-12.039,7.49-20.867,20.199-20.867C369.221,49.491,376.979,58.185,376.979,70.358
		L376.979,70.358z M376.176,107.145h19.799L395.973,6l-19.797,10.724v24.473c-3.744-4.95-11.637-9.23-22.34-9.23
		c-23.275,0-36.918,17.256-36.918,38.392c0,21.136,13.643,38.392,36.918,38.392c10.436,0,19.264-4.816,22.34-8.963V107.145
		L376.176,107.145z M198.215,49.758c10.033,0,16.588,7.357,17.39,14.045h-36.786C179.622,56.313,186.711,49.758,198.215,49.758
		L198.215,49.758z M272.564,49.758c-10.033,0-16.588,7.357-17.391,14.045h36.787C291.158,56.313,284.068,49.758,272.564,49.758
		L272.564,49.758z"/>
                    </g>
                </svg>
                <div className="lds-ellipsis">
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                </div>
            </div>
        </div>
    )
};

export default LoadingOverlay;