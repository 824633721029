import React, {Component} from "react";
import HeaderStaticPage from "../../Components/HeaderStaticPage";
import TermsCZ from "../../Components/TermsCZ";
import TermsEN from "../../Components/TermsEN";
import {withTranslation, Translation} from 'react-i18next';

class TermsAndConditions extends Component {

    componentDidMount() {
        window.scrollTo(0, 0);
    }

    render() {

        const {i18n} = this.props;
        const changeLanguage = lng => {
            i18n.changeLanguage(lng);
        };

        return (
            <Translation>
                {t =>
                <div className={"StaticPage"}>
                    <HeaderStaticPage
                        headline={t('terms')}
                    />
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="content">
                                    {i18n.language === "cs" ?
                                        <TermsCZ/>
                                        :
                                        <TermsEN/>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                }
            </Translation>
        )
    }
}

export default withTranslation()(TermsAndConditions);