import React, { Component } from "react"

import cogoToast from "cogo-toast"
import { ApolloConsumer } from "react-apollo"
import { Trans, Translation } from "react-i18next"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"
import XMLParser from "react-xml-parser"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

import {
  actionFetchBranchesDone,
  actionFetchBranchesError,
  actionFetchBranchesStart,
  actionLoadUserAddress,
} from "../../actions/branchesAction"
import { GET_BRANCHES } from "../../GqlQueries/GET_BRANCHES"
import LoadingOverlay from "./LoadingOverlay"
import { StringWebalizer } from "./StringWebalizer"

class SearchForm extends Component {
  constructor() {
    super()

    this.state = {
      searchInputValue: "",
    }
  }

  componentDidMount() {
    if (localStorage.getItem("lastAddress") !== null) {
      this.setState({
        searchInputValue: localStorage.getItem("lastAddress"),
      })
    }
  }

  getLoc = () => {
    if (this.props.coords !== undefined && this.props.coords !== null) {
      // https://mapy.cz/rgeocode?lon=15.762271&lat=50.032263
      let lat = this.props.coords.latitude
      let lon = this.props.coords.longitude
      let url = `https://api.mapy.cz/rgeocode?lon=${lon}&lat=${lat}`
      fetch(url)
        .then((response) => {
          return response.text()
        })
        .then((response) => {
          const xmlRes = new XMLParser().parseFromString(response)
          let address = xmlRes.attributes.label
          document.getElementById("address_to_delivery").value = address
          this.props.loadAddress(address)
          this.setState({
            searchInputValue: address,
          })
        })
        .catch((err) => {
          console.error("fetch", err)
        })
    } else {
      cogoToast.error(<Trans i18nKey={"error_cannot_load_address"} />)
    }
  }

  handleChange = (value) => {
    this.setState({
      searchInputValue: value,
    })
  }

  render() {
    return (
      <Translation>
        {(t) => (
          <form id={"search-restaurant"}>
            <div className="input-group">
              <input
                type="text"
                className="form-control"
                placeholder={t("enter_address")}
                autoComplete="off"
                id="address_to_delivery"
                value={this.state.searchInputValue}
                onChange={(e) => this.handleChange(e.target.value)}
                autoFocus={true}
              />
              <div className="map_pointer">
                <FontAwesomeIcon icon={"map-marker-alt"} />
              </div>
              {/* <div className="auto_location" onClick={() => this.getLoc({t})}>
                                <FontAwesomeIcon icon={"crosshairs"}/>
                            </div> */}
            </div>
            <div className="input-group-append">
              <ApolloConsumer>
                {(client) => (
                  <button
                    className="btn btn-outline-secondary"
                    type="submit"
                    name="sendTicketRequest"
                    disabled={this.props.branches.loading && this.props.branches.address}
                    onClick={async (e) => {
                      e.preventDefault()
                      if (
                        this.state.searchInputValue === null ||
                        this.state.searchInputValue.length <= 0
                      ) {
                        cogoToast.warn(t("location_delivery"))
                        return
                      }

                      try {
                        this.props.branchStart()
                        const { loading, data, error } = await client.query({
                          query: GET_BRANCHES,
                          variables: { addressSearch: this.state.searchInputValue },
                        })

                        if (loading) {
                          this.props.branchStart()
                        } else if (error) {
                          this.props.branchError(error)
                          cogoToast.error(error)
                        } else {
                          if (data.companyBranchesLookUp.isValidAddress) {
                            if (data.companyBranchesLookUp.resolvedBranches.length !== 0) {
                              const storage = window.localStorage
                              storage.setItem(
                                "lastAddress",
                                data.companyBranchesLookUp.finalAddressFormatted
                              )
                              this.props.branchStop(data)
                              this.props.history.push(
                                "/doveze/" +
                                  StringWebalizer(data.companyBranchesLookUp.finalAddressFormatted)
                              )
                            } else {
                              cogoToast.error(t("error_delivery_not_in_range"))
                              this.props.branchStop(data)
                            }
                          } else {
                            this.props.branchStop()
                            cogoToast.error(t("error_unknown_address"))
                          }
                        }
                      } catch (error) {
                        this.props.branchStop()
                        cogoToast.error(t("error_something_went_wrong"))
                      }
                    }}
                  >
                    {t("search_restaurants")}
                  </button>
                )}
              </ApolloConsumer>
            </div>
            {this.props.branches.loading ? <LoadingOverlay /> : null}
          </form>
        )}
      </Translation>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    branches: state.branches,
  }
}

const mapStateToDispatch = (dispatch) => {
  return {
    branchStart: () => dispatch(actionFetchBranchesStart()),
    branchStop: (data) => dispatch(actionFetchBranchesDone(data)),
    branchError: (error) => dispatch(actionFetchBranchesError(error)),
    loadAddress: (address) => dispatch(actionLoadUserAddress(address)),
  }
}

// export default geolocated({
//   positionOptions: {
//     enableHighAccuracy: true,
//   },
//   userDecisionTimeout: 3000,
// })(connect(mapStateToProps, mapStateToDispatch)(withRouter(SearchForm)))

export default connect(mapStateToProps, mapStateToDispatch)(withRouter(SearchForm))
