import React, { Component } from 'react';

import {
  Translation,
  withTranslation,
} from 'react-i18next';

import HeaderStaticPage from '../../Components/HeaderStaticPage';
import PrivacyPolicyCZ from '../../Components/PrivacyPolicyCZ';
import PrivacyPolicyEN from '../../Components/PrivacyPolicyEN';

class PrivacyPolicy extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    const { i18n } = this.props;
    const changeLanguage = (lng) => {
      i18n.changeLanguage(lng);
    };

    return (
      <Translation>
        {(t) => (
          <div className={"StaticPage"}>
            <HeaderStaticPage headline={t("privacy_policy")} />

            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <div className="content privacy-policy">
                    {i18n.language === "cs" ? (
                      <PrivacyPolicyCZ />
                    ) : (
                      <PrivacyPolicyEN />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </Translation>
    );
  }
}

export default withTranslation()(PrivacyPolicy);
