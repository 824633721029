import React, { Fragment } from 'react';

const PrivacyPolicyEN = () => {
  return (
    <Fragment>
      <h2>1. Who are we?</h2>
      <p>
        We are speedlo s.r.o., registration number: 08750866, registered office: Hlaváčova 207,
        Zelené Předměstí, 530 02 Pardubice (hereinafter also referred to as <strong>„we“</strong>) a
        company operating the website at <a href="https://speedlo.cz">https://speedlo.cz</a>, which
        may have subpages for individual restaurants and our mobile app (hereinafter also referred
        to as the <strong>„App“</strong>) We also provide services to restaurants in connection with
        our software and hardware system (hereinafter also referred to as{" "}
        <strong>„Services“</strong>). . Although, when you order food from a restaurant, the
        specific restaurant you order from plays the role of personal data controller, in some cases
        we too may act as a personal data controller and process your personal data.
      </p>
      <p>
        Below we provide basic information on how we handle your personal data from the position of
        a personal data controller.
      </p>
      <hr />
      <h2>2. What personal data do we process?</h2>
      <p>We process the following information:</p>
      <ul>
        <li>
          <h3>
            If you are a client of our Services, or a representative of a client, or if you enquire
            about our Services via the contact form
          </h3>
          <p>
            We process your identification data, contact data, specifically (for example) your first
            name, surname, telephone number, email address, identifying details (registration and
            VAT number) and other information that you provide to us for purposes of a contractual
            relationship. This information may relate to your responsible officers, beneficial
            owners, agents, or other persons authorised to act on your behalf.
          </p>
          <p>
            In order to make the payment gateway available and provide payment gateway services, we
            need additional information in connection with Act No 253/2008 on certain measures to
            combat money laundering and terrorist financing. We may also require other information
            requested of us by the payment gateway operator. In connection with this processing, we
            may also require a copy of your identity card and other information that needs to be
            obtained by a liable party to establish your identity. We may also process this
            information about your responsible officers, beneficial owners, agents, or other persons
            authorised to act on your behalf.
          </p>
          <p>
            In the provision of Services, we may process additional personal data necessary for our
            contractual relationship.
          </p>
        </li>
        <li>
          <h3>In connection with the Application in fulfilling legal obligations</h3>
          <p>
            As operators of the Application, we must fulfill the obligations stipulated by Act No. 164/2013 
            on international cooperation in tax administration, as amended (“DAC7”). This law obliges us to 
            identify sellers on the Application and report this data as a tax entity to the relevant authorities. 
            We are obliged to inform about this processing in the sense of § 14zzd DAC7. 
          </p>
          <p>
            In connection with this obligation, we will process your name, address, tax identification numbers, 
            or if none is assigned, place of birth, date of birth, and other necessary data specified in 
            Annex No. 4 to DAC7. We will verify, collect, and report all your information to the relevant tax office.
          </p>
        </li>

        <li>
          <h3>When using the Restaurant Ordering App operated by us</h3>
          <p>
            When the App is used, certain information may be collected passively. This includes, but
            is not limited to, your IP address, browser type, and operating system type. We also use
            cookies and navigation data to collect information on the date and time of your visit
            and the information you searched for and viewed. When you use our mobile apps, we may
            also obtain information about how, on your device, you use the Services we provide to
            our customers, including information about your location, if you have enabled this
            feature on your mobile device.
          </p>
          <p>
            If our App allows you to set up a user account, we will process the personal data you
            provide to us for the purposes of setting up the user account, and subsequently other
            information obtained in the use of the user account (such as credit earned).
          </p>
        </li>

        <li>
          <h3>When using the Speedlo Driver App</h3>
          <p>This includes:</p>
          <h5>Location data (driver and delivery staff):</h5>
          <p>
            We collect precise or approximate location data from drivers' and delivery staff's
            mobile devices when the speedlo Driver app is running in the foreground (app open and on
            screen) or in the background (app open but not on screen).
          </p>
          <p>
            Speedlo collects this data from the moment the driver or delivery staff logs "on to the
            vehicle" in the app for as long as the app is running and has system location access
            permissions enabled.
          </p>
          <p>
            Drivers or delivery staff can use the speedlo Driver app without enabling the collection
            of location data from their mobile devices. However, this may affect some features of
            the app. For example, restaurant staff will not see the driver's location on the map in
            the administration and this location will not be properly recorded and stored for
            further evaluation.
          </p>
          <p>
            In addition, any location data collected from the driver's device during the ride is
            linked to the account of the driver or delivery staff currently logged into the speedlo
            Driver app, even if they have not authorized the collection of accurate location data
            from their device. This data is used, for example, for the purposes of generating a
            logbook, customer support, fraud detection, etc.
          </p>
          <h5>Transaction information:</h5>
          <p>
            We collect transaction information related to your use of our services, including the
            type of services requested or provided, travel or order information (such as date and
            time, requested pick-up and drop-off address, distance traveled, and items ordered), and
            payment transaction information (such as the name and location of the restaurant or
            merchant, amount charged, and method of payment).
          </p>
          <h5>Usage data:</h5>
          <p>
            We collect data about how users interact with our Services. This includes dates and
            times of accesses, application features or map applications used, type of operating
            system application crashes, and other system activity.
          </p>
          <h5>Device data:</h5>
          <p>
            We collect data about the devices used to access our Services, including hardware
            models, device IP address or other unique device identifiers, operating systems and
            versions, software, preferred languages, device movement data, and mobile network data.
          </p>
        </li>

        <li>
          <h3>
            If you contact us via the contact form or enter your phone number, but do not wish to
            subscribe to our Services
          </h3>
          <p>
            We will process the personal data that you provide in the contact form, i.e. your first
            name, surname, email address, phone number, and other information that you volunteer in
            the text of the message or that you tell us by phone.
          </p>
        </li>
      </ul>
      <hr />
      <h2>3. Why do we process your personal data?</h2>
      <ul>
        <li>
          <h3>
            If you are a client of our Services, or a representative of a client, or if you enquire
            about our Services via the contact form.
          </h3>
          <p>
            We process your personal data in order to enable you to access our Services and in order
            to comply with all obligations that may arise from our relationship. We also need your
            personal data to fulfil our legal obligations.
          </p>
          <p>
            The legal basis in this situation is the performance of contractual obligations and the
            fulfilment of legal obligations.
          </p>
          <p>
            If a dispute arises between us and you or if we need to use any of your personal data
            for administrative or other proceedings, we will process your personal data for these
            purposes on the grounds that we have a legitimate interest in protecting our own legal
            claims.
          </p>
          <p>
            If Services include the provision of a payment gateway, in this respect the personal
            data you have provided will be processed for transfer to the bank operating the payment
            gateway in order to identify you sufficiently and to comply with all obligations
            required of us. The legal basis for this processing is the fulfilment of the contractual
            obligation between us and you, as well as compliance with legal obligations that apply
            to us in this context or the legal obligations that apply to the bank operating the
            payment gateway that we help to collect the data.
          </p>
        </li>
        <li>
          <h3>Information obtained through the use of our App</h3>
          <p>
            We use this data to better understand how you use the App and to resolve various
            servicing and other needs that may be related to the operation of the App. Personal data
            is processed on the basis that we have a legitimate interest in ensuring the
            functionality of the App and improving our Services.
          </p>
          <p>
            We will process personal data when a user account is set up and used. This processing is
            based on our contractual obligation and our legitimate interest in ensuring the
            functioning of the user account and offering special promotions associated with that
            account.
          </p>
        </li>
        <li>
          <h3>
            If you contact us via the contact form or enter your phone number, but do not wish to
            subscribe to our Services
          </h3>
          <p>
            We process personal data in order to handle your request or answer your enquiry. This
            processing is based on our legitimate interest in engaging in communication between you
            and us.
          </p>
        </li>
        <li>
          <h3>
            In connection with the Application in fulfilling legal obligations under DAC7
          </h3>
          <p>
            Personal data in connection with the fulfillment of obligations stipulated in DAC7, 
            i.e., for the purposes of recording, assessing, and subsequently reporting information 
            to the relevant administrative authorities, to fulfill our obligation as a tax entity. 
            This processing will include the transfer of personal data to the tax administrator. 
            Personal data will be processed provided that you are a so-called reportable seller, 
            meaning you meet the thresholds set by DAC7. 
          </p>
        </li>
      </ul>
      <hr />
      <h2>4. How long will we process your personal data?</h2>
      <p>
        We will process your personal data only for the time required by the above purposes for
        which it was collected – the provision of Services, the functioning of the App, and the
        fulfilment of the related legal obligations. Once the purposes for which personal data was
        collected have expired and there is no other purpose it can serve, we will delete or
        anonymise it.
      </p>
      <p>
        In connection with the payment gateway, we will retain personal data for as long as we are
        required to do so by law. Please note that the bank operating the payment gateway will store
        this information for 10 years.
      </p>
      <p>
        In connection with fulfilling the obligations under § 14zze DAC7, we will retain your personal 
        data for a period of 10 years from the end of the reporting period (calendar year). 
      </p>
      <h2>5. Who has access to your personal data?</h2>
      <p>We transfer your personal data:</p>
      <ul>
        <li>to restaurants if you have a question or request specific to that restaurant;</li>
        <li>to the sales representatives who organise contracting for us;</li>
        <li>
          if you wish to use the payment gateway, to the bank providing the payment gateway, i.e.
          Československá obchodní banka, a. s., registered office: Praha 5, Radlická 333/150, 15057,
          registration number: 00001350;
        </li>
        <li>
          to our IT, hosting and marketing service providers, providers of statistical, database and
          other software tools, and, where applicable, other entities that provide their services to
          us.
        </li>
      </ul>
      <p>
        There are certain circumstances where we may be required by law to share your personal data
        with third parties (such as law enforcement agencies) without your consent. In connection with 
        fulfilling the obligations under DAC7, your personal data will be shared with the relevant 
        tax authority to this extent. This is a legal obligation for us.
      </p>
      <hr />

      <h2>6. What rights do you have?</h2>
      <p>You have the following rights in relation to our processing of your personal data:</p>
      <ul>
        <li>
          <h3>Right of access.</h3>
          <p>
            You have the right to obtain confirmation of whether we process personal data about you,
            and if so what personal data. This includes the right to receive copies of the personal
            data we process. Please note that it will not be possible to disclose information that
            would compromise our business secrets or the rights of third parties.
          </p>
        </li>
        <li>
          <h3>Right to rectification and completion.</h3>
          <p>
            If you discover that the personal data we process about you is inaccurate or incomplete,
            you have the right to ask us to correct and update it.
          </p>
        </li>
        <li>
          <h3>Right to erasure.</h3>
          <p>
            In certain cases, you have the right to contact us to request the erasure of the
            personal data we process about you. Please note that the erasure of personal data is not
            always possible, especially if it is processed for a legitimate purpose and in
            accordance with data protection regulations.
          </p>
        </li>
        <li>
          <h3>Right to restriction of processing.</h3>
          <p>
            In certain cases, such as when you doubt the accuracy or lawfulness of the processing of
            your personal data, or our need to process it, you have the right to ask us to restrict
            the processing of your personal data. If we comply with your request, we will restrict
            the processing of your personal data to the minimum necessary (and, as a rule, we will
            not actively process the data further). We will make a record in our systems that the
            data is subject to restriction. If the restriction is lifted and we plan to continue
            processing your personal data, we will inform you of this in advance.
          </p>
        </li>
        <li>
          <h3>Right to portability.</h3>
          <p>
            You have the right to request that we transfer the personal data that you have provided
            to us and that we process (in a commonly used and machine-readable format) to a third
            party (another data controller) specified in your request. Please note that you can only
            exercise this right if we process your personal data on the basis of your consent or for
            the purposes of a contract and the processing is automated.
          </p>
        </li>
        <li>
          <h3>Right to object.</h3>
          <p>
            You may object to the processing of your personal data that we process for direct
            marketing purposes or on the basis of a legitimate interest. If you object to processing
            for direct marketing purposes, your personal data will no longer be processed for such
            purposes. If you object to processing on other grounds, we will evaluate the objection
            and then inform you whether we have accepted your objection and will no longer process
            your data, or whether the objection was not justified and processing will continue. In
            any event, processing will be restricted until the objection is resolved.
          </p>
        </li>
        <li>
          <h3>Right to lodge a complaint with a supervisory authority.</h3>
          <p>
            If you believe that there has been a breach of data protection legislation, you have the
            right to lodge a complaint with the Office for Personal Data Protection or with another
            authority of a Member State of the European Union responsible for supervising compliance
            with data protection obligations. As you are important to us, we would be highly
            appreciative if you contacted us directly before lodging a complaint, so that we have an
            opportunity to resolve the entire matter with you directly.
          </p>
          <p>
            You can exercise all your rights at{" "}
            <a href="mailto:info@speedlo.cz">info&#64;speedlo.cz</a>. If you use payment gateway
            services and would like to exercise your right to access, restrict or erase your
            personal data collected for purposes of accessing the payment gateway, please contact us
            directly with this request and we will deal with it together with the payment gateway
            provider.
          </p>
          <p>This policy takes effect as of 1st January 2024.</p>
        </li>
      </ul>
    </Fragment>
  )
}

export default PrivacyPolicyEN
