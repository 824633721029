import React, { Component } from 'react';

import {
  Translation,
  withTranslation,
} from 'react-i18next';

import HeaderStaticPage from '../../Components/HeaderStaticPage';
import PersonalInformationCZ from '../../Components/PersonalInformationCZ';
import PersonalInformationEN from '../../Components/PersonalInformationEN';

class PersonalInformations extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    const { i18n } = this.props;
    const changeLanguage = (lng) => {
      i18n.changeLanguage(lng);
    };

    return (
      <Translation>
        {(t) => (
          <div className={"StaticPage"}>
            <HeaderStaticPage headline={t("personal_information")} />

            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <div className="content gdpr">
                    {i18n.language === "cs" ? (
                      <PersonalInformationCZ />
                    ) : (
                      <PersonalInformationEN />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </Translation>
    );
  }
}

export default withTranslation()(PersonalInformations);
