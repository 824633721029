import React, { Component } from "react"

import { withTranslation } from "react-i18next"
import { Link } from "react-router-dom"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

class Navbar extends Component {
  constructor() {
    super()
    this.state = {
      langShow: false,
    }
  }

  // Funkce pro otevření/zavření dropdown menu
  toggleLang = () => {
    this.setState({
      langShow: !this.state.langShow,
    })
  }

  // Funkce pro změnu jazyka a zavření dropdown menu
  changeLanguageAndClose = (lng) => {
    const { i18n } = this.props
    i18n.changeLanguage(lng)
    this.setState({ langShow: false })
  }

  render() {
    return (
      <nav className="navbar navbar-expand-lg navbar-light">
        <div className="container">
          <Link to={"/"} className="navbar-brand justify-content-center">
            <img src={"../portal_static/img/speedlo.svg"} id="navbar-logo" />
          </Link>
          <div className="language-toggle">
            <button
              onClick={() => this.toggleLang()}
              className={"dropdown-toggle"}
              id={"navbarDropdown"}
              role={"button"}
              aria-haspopup={"true"}
              aria-expanded={this.state.langShow ? "true" : "false"}
            >
              <FontAwesomeIcon icon={"globe-europe"} />
              <FontAwesomeIcon className="chevron" icon={"chevron-down"} />
            </button>
            <div
              className={"dropdown-menu " + (this.state.langShow ? "show" : "")}
              aria-labelledby="navbarDropdown"
            >
              <li>
                <button
                  onClick={() => this.changeLanguageAndClose("cs")}
                  className="link-button nav-link"
                >
                  CZ
                </button>
              </li>
              <li>
                <button
                  onClick={() => this.changeLanguageAndClose("en")}
                  className="link-button nav-link"
                >
                  EN
                </button>
              </li>
            </div>
          </div>
        </div>
      </nav>
    )
  }
}

export default withTranslation()(Navbar)
