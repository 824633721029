import {
    BRANCHES_FETCH_LOADED,
    BRANCHES_FETCH_ERROR,
    BRANCHES_FETCH_START,
    BRANCHES_FETCH_DONE
} from "../actions/branchesAction";

export default (state = {
    data: null,
    error: false,
    loading: false,
    address: null
}, action) => {
    if (action.type === BRANCHES_FETCH_DONE) {
        return {
            ...state,
            data: action.branches,
            error: null,
            loading: false
        }
    } else if (action.type === BRANCHES_FETCH_ERROR) {
        return {
            ...state,
            error: action.error,
            loading: false
        }
    } else if (action.type === BRANCHES_FETCH_START) {
        return {
            ...state,
            loading: true
        }
    } else if (action.type === BRANCHES_FETCH_LOADED) {
        return {
            ...state,
            address: action.address
        }
    }

    return state;
}