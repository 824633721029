import {gql} from "apollo-boost";

const GET_BRANCHES_DISCOUNTS = gql`
    query getBranches($addressSearch: String!, $cbArrayIds: [ID!]){
        companyBranchesLookUp(address: $addressSearch, companyBranchIds: $cbArrayIds) {
            finalAddressFormatted
            isResolved
            isValidAddress
            resolvedBranches {
                url
                companyBranch {
                    id
                    brand {
                        url
                        subdomain
                    }
                    name
                    headerText
                    urlPath
                    isOpen
                    businessHoursIntervals {closedNote}
                    willOpenAt
                    willCloseAt
                    defaultDeliveryTime
                    defaultDeliveryTimeEnabled
                    enabled
                    styles{storeLogo}
                    android:mobileApplicationSetting(platform: ANDROID) {
                        url
                    }
                    ios:mobileApplicationSetting(platform: IOS) {
                        url
                    }
                    pwa: mobileApplicationSetting(platform: IOS) {
                        url
                    }
                }
            }
        }
    }
`;

export default GET_BRANCHES_DISCOUNTS;