import React, {Component} from "react";
import {GET_BRANCHES} from "../../GqlQueries/GET_BRANCHES";
import LoadingOverlay from "./LoadingOverlay";
import BranchItem from "./BranchItem";
import {Query} from "react-apollo";
import PropTypes from "prop-types";
import {
    actionFetchBranchesDone,
    actionFetchBranchesError,
    actionFetchBranchesStart, actionLoadUserAddress
} from "../../actions/branchesAction";
import {connect} from "react-redux";
import cogoToast from "cogo-toast";
import {Redirect} from "react-router";
import {Translation} from 'react-i18next';

class HelperRefetchData extends Component {

    render() {
        const {address, deliveryTypeState} = this.props;
        return (
            <Translation>
                {t =>
                    <Query query={GET_BRANCHES} variables={{addressSearch: address}}>
                        {({loading, error, data}) => {

                            if (loading) {
                                return <LoadingOverlay/>;
                            } else if (error) {
                                this.props.branchError(error);
                                console.error(`Chyba: ${error}`)
                            }

                            if (data.companyBranchesLookUp.resolvedBranches.length <= 0) {
                                return <Redirect to={"/"}
                                                 push={cogoToast.error(t('error_delivery_not_in_range'))}/>
                            } else if (data.companyBranchesLookUp.isValidAddress === false) {
                                return <Redirect to={"/"}
                                                 push={cogoToast.error(t('error_unknown_address'))}/>
                            } else {
                                const storage = window.localStorage;
                                storage.setItem('lastAddress', data.companyBranchesLookUp.finalAddressFormatted);
                                this.props.branchStop(data);
                                return data.companyBranchesLookUp.resolvedBranches
                                    .sort((a, b) => b.companyBranch.isOpen - a.companyBranch.isOpen)
                                    .map(({companyBranch, url}, index) => (
                                        companyBranch.enabled ?
                                            <BranchItem
                                                key={index}
                                                item={companyBranch}
                                                deliveryType={deliveryTypeState}
                                                address={data.companyBranchesLookUp.finalAddressFormatted}
                                            />
                                            : null
                                    ));
                            }

                        }}
                    </Query>
                }
            </Translation>
        )
    }
}

HelperRefetchData.propTypes = {
    address: PropTypes.string.isRequired,
    deliveryTypeState: PropTypes.bool.isRequired
};

const mapStateToProps = (state) => {
    return {
        data: state.branches.data,
        branches: state.branches,
    }
};

const mapStateToDispatch = dispatch => {
    return {
        branchStart: () => dispatch(actionFetchBranchesStart()),
        branchStop: data => dispatch(actionFetchBranchesDone(data)),
        branchError: error => dispatch(actionFetchBranchesError(error)),
        loadAddress: (address) => dispatch(actionLoadUserAddress(address)),
    }
};

export default connect(mapStateToProps, mapStateToDispatch)(HelperRefetchData);