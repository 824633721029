import React from 'react';

import TagManager from 'react-gtm-module';
import { withTranslation } from 'react-i18next';

import { BASE_COOKIE_CONFIG } from '../../cookieConfig';

// this has to correspond BASE_COOKIE_CONFIG.languages["every language"].settings_modal.blocks["analytics block"].toggle.value value
const ANALYTICS_COOKE_KEY = `analytics`;

const TAG_MANAGER_OPTIONS = {
  gtmId: "GTM-KJFLJHM", // ? should this bu public?
  dataLayerName: "portal_speedlo",
};

const CookieConsent = ({ i18n }) => {
  // check if tracking/analytics cookie is allowed by the user
  // and if yes, init the GTM
  const initTagManager = React.useCallback((consentCookie) => {
    const isTrackingAllowed = isTrackingCookieEnabled(consentCookie);

    if (isTrackingAllowed) {
      TagManager.initialize(TAG_MANAGER_OPTIONS);
    }

    return isTrackingAllowed;
  }, []);

  // initialize cookie consent lib
  // and hook tag manager init on user actions
  React.useEffect(() => {
    // the delay is needed to wait for lib scripts in index.html to load
    setTimeout(() => {
      const consent = window.initCookieConsent();

      consent.run({
        ...BASE_COOKIE_CONFIG,
        current_lang: i18n.language,

        // this is called
        //  - when the cookie is first created (user’s first interaction)
        //  - when the consent lib is run and the cookie was already set
        onAccept: initTagManager,

        // this is called
        //  - when the user changes settings via the modal
        onChange: (consentCookie) => {
          const isTrackingAllowed = initTagManager(consentCookie);

          if (!isTrackingAllowed) {
            // TagManager.initialize can’t be called twice on one load -> reload the page when the user turns off tracking
            // eslint-disable-next-line no-restricted-globals
            location.reload();
          }
        },
      });
    }, 2000);
  }, [i18n.language, initTagManager]);

  return null;
};

export default withTranslation()(CookieConsent);

// * HELPERS

const isTrackingCookieEnabled = (consentCookie) => {
  const isArray = Array.isArray(consentCookie.level);
  const hasSomeCookieEnabled = isArray && consentCookie.level.length > 0;

  if (!hasSomeCookieEnabled) {
    return false;
  }

  return consentCookie.level.includes(ANALYTICS_COOKE_KEY);
};
