import React, { Fragment } from 'react';

const PrivacyPolicyCZ = () => {
  return (
    <Fragment>
      <h2>1. Kdo jsme?</h2>
      <p>
        Jsme společnost speedlo s.r.o., IČO: 08750866, se sídlem Hlaváčova 207, Zelené Předměstí,
        530 02 Pardubice (dále také
        <strong>„my“</strong>) a provozujeme webové stránky{" "}
        <a href="https://speedlo.cz">https://speedlo.cz</a>, které mohou mít podstránky pro
        jednotlivé restaurace a mobilní aplikaci (dále také <strong>„Aplikace“</strong>) a také
        poskytujeme služby restauracím v souvislosti s našim softwarovým a hardwarovým systémem
        (dále také <strong>„Služby“</strong>). Ač při objednávce jídel vystupuje konkrétní
        restaurace jako správce osobních údajů, může dojít k tomu, že v některých případech budeme
        zpracovávat Vaše osobní údaje v pozici správce osobních údajů i my.
      </p>
      <p>
        Níže naleznete základní informace o tom, jak s Vašimi osobními údaji nakládáme jako správci
        osobních údajů.
      </p>
      <hr />
      <h2>2. Jaké osobní údaje zpracováváme?</h2>
      <p>Zpracováváme následující informace:</p>
      <ul>
        <li>
          <h3>
            V případě, že jste objednatelem našich Služeb, případně zástupcem objednatele, nebo
            poptáváte naše služby prostřednictvím kontaktního formuláře
          </h3>
          <p>
            V takovém případě zpracováváme Vaše identifikační údaje, kontaktní údaje, konkrétně
            například Vaše jméno, příjmení, telefonní číslo, e-mailovou adresu, identifikační údaje
            (IČO, DIČ) a další informace, které nám sdělíte pro případné plnění smluvního vztahu.
            Tyto informace se mohou týkat Vašich odpovědných osob, skutečných vlastníků, zástupců či
            jiných osob oprávněných jednat Vašim jménem.
          </p>
          <p>
            Pro účely zpřístupnění platební brány a poskytnutí služeb platební brány potřebujeme
            získat některé další informace v souvislosti se zákonem č. 253/2008 Sb., o některých
            opatřeních proti legalizaci výnosů z trestné činnosti a financování terorismu, případně
            další informace, které po nás provozovatel platební brány bude vyžadovat. V souvislosti
            s tímto zpracováním může být vyžadována i kopie průkazu totožnosti a další informace,
            které má povinná osoba v souvislosti s Vaší identifikací získat. I tyto informace můžeme
            zpracovávat o Vašich odpovědných osobách, skutečných vlastnících, zástupcích i dalších
            osobách oprávněných jednat Vašim jménem.
          </p>
          <p>
            V průběhu poskytování Služeb může docházet k tomu, že budeme zpracovávat další osobní
            údaje potřebné pro splnění smluvního vztahu.
          </p>
        </li>
        <li>
          <h3>V souvislosti s Aplikací při plnění zákonných povinností</h3>
          <p>
            Jako provozovatelé Aplikace musíme plnit povinnosti stanovené zákonem č. 164/2013, 
            o mezinárodní spolupráci při správě daní, ve znění pozdějších předpisů („DAC7“). 
            Tento zákon nám ukládá povinnost identifikovat prodejce na Aplikaci a tato data oznamovat 
            jako daňový subjekt příslušným orgánům. O tomto zpracování jsme povinni ve smyslu § 14zzd DAC7. 
          </p>
          <p>
          V souvislosti s touto povinností budeme zpracovávat Vaše jméno, adresu, daňová identifikační čísla, 
          nebo pokud nebylo přiřazeno, místo narození, datum narození a další nezbytné údaje stanovené 
          přílohou č. 4 k DAC7. Všechny Vaše informace budeme ověřovat, shromažďovat a oznamovat příslušnému daňovému úřadu. 
          </p>
        </li>
        <li>
          <h3>Při využívání objednávací Aplikace restaurace, provozované námi</h3>
          <p>
            Při používání Aplikace mohou být určité informace shromažďovány pasivně. Jedná se
            především o IP adresu, typ prohlížeče a druh operačního systému. Používáme také soubory
            cookies a navigační data, abychom shromáždili informace týkající se data a času Vaší
            návštěvy a informací, které jste vyhledávali a zobrazovali. Pokud využíváte naše mobilní
            aplikace, můžeme získat také informace o tom, jak na Vašem zařízení využíváte Služby,
            které poskytujeme svým zákazníkům, a to včetně informace o Vaší poloze, pokud jste na
            Vašem mobilním zařízení tuto funkci povolili.
          </p>
          <p>
            Pokud naše Aplikace umožňuje zřídit uživatelský účet, budeme zpracovávat Vaše osobní
            údaje, které nám předáte pro účely zřízení uživatelského účtu a následně další informace
            získané při využívání uživatelského účtu (jako například získaný kredit).
          </p>
        </li>
        <li>
          <h3>Při využívání Aplikace speedlo Driver</h3>
          <p>Sem patří:</p>
          <h5>Údaje o poloze (řidič a doručovatel):</h5>
          <p>
            Shromažďujeme přesné nebo přibližné údaje o poloze z mobilních zařízení řidičů a
            doručovatelů, když je aplikace speedlo Driver spuštěna na popředí (aplikace je otevřená
            a na obrazovce), nebo na pozadí (aplikace je otevřená, ale není na obrazovce).
          </p>
          <p>
            Speedlo tyto údaje shromažďuje od okamžiku přihlášení řidiče, nebo doručovatele “k
            vozidlu” v aplikaci po celou dobu, kdy je aplikace spuštěna a má povolené systémové
            oprávnění přístupu k poloze.
          </p>
          <p>
            Řidiči, nebo doručovatélé mohou aplikace speedlo Driver používat, aniž by sběr údajů o
            poloze ze svých mobilních zařízení povolili. To však může mít vliv na některé funkce
            aplikace. Například personál neuvidí polohu jezdce na mapě v administraci a tato poloha
            se nebude správně zazanamenávat a ukládat pro další vyhodnocování.
          </p>
          <p>
            Kromě toho jsou všechny údaje o poloze shromážděné ze zařízení řidiče během jízdy
            propojeny s účtem řidiče, nebo doručovatele aktuálně přihlášeného v aplikaci speedlo
            Driver, i když shromažďování přesných údajů o poloze ze svého zařízení nepovolil. Tyto
            údaje se používají například pro účely generování knihy jízd, zákaznické podpory,
            odhalování podvodů apd.
          </p>
          <h5>Informace o transakcích</h5>
          <p>
            Shromažďujeme informace o transakcích souvisejících s využíváním našich služeb, včetně
            typu požadovaných nebo poskytnutých služeb, údajů o cestě nebo objednávce (například
            datum a čas, požadovaná adresa vyzvednutí a předání, ujetá vzdálenost a objednané
            položky) a informací o platebních transakcích (například název a umístění restaurace
            nebo obchodníka, účtovaná částka a způsob platby).
          </p>
          <h5>Údaje o používání:</h5>
          <p>
            Shromažďujeme údaje o tom, jak uživatelé pracují s našimi službami. Patří sem data a
            časy přístupů, funkce aplikace nebo používané mapové aplikace, typ operačního systému
            pády aplikace a další systémové aktivity.
          </p>
          <h5>Údaje o zařízení:</h5>
          <p>
            Shromažďujeme údaje o zařízeních používaných k přístupu k našim službám, včetně modelů
            hardwaru, IP adresy zařízení nebo jiných jedinečných identifikátorů zařízení, operačních
            systémů a verzí, softwaru, preferovaných jazyků, údajů o pohybu zařízení a údajů o
            mobilní síti.
          </p>
        </li>
        <li>
          <h3>
            Pokud nás kontaktujete prostřednictvím kontaktního formuláře, nebo zadáte své telefonní
            číslo a nechcete odebírat naše Služby.
          </h3>
          <p>
            V takovém případě budeme zpracovávat osobní údaje, které uvedete v kontaktním formuláři,
            tedy například Vaše jméno, příjmení, e-mailovou adresu, telefonní číslo a další
            informace, které uvedete v textu zprávy, nebo které nám telefonicky sdělíte.
          </p>
        </li>
      </ul>
      <hr />
      <h2>3. Pro jaké účely zpracováváme Vaše osobní údaje?</h2>
      <ul>
        <li>
          <h3>
            V případě, že jste objednatelem našich Služeb, případně zástupcem objednatele, nebo
            poptáváte naše služby prostřednictvím kontaktního formuláře.
          </h3>
          <p>
            Vaše osobní údaje zpracováváme, abychom Vám umožnili přístup k našim Službám, abychom
            mohli plnit všechny povinnosti, které mohou vyplývat z našeho vzájemného vztahu. Zároveň
            Vaše osobní údaje potřebujeme pro účely plnění zákonných povinností.
          </p>
          <p>
            Právním základem v takovém případě je plnění smluvních povinností a plnění zákonných
            povinností.
          </p>
          <p>
            V případě, že by mezi námi a Vámi došlo ke sporu, případně bychom museli jakékoliv Vaše
            osobní údaje využít pro správní a jiná řízení, budeme pro tyto účely zpracovávat Vaše
            osobní údaje na základě našeho oprávněného zájmu, který spočívá v ochraně vlastních
            právních nároků.
          </p>
          <p>
            V případě, že součástí Služeb je i zajištění platební brány, budeme Vámi předané osobní
            údaje v této souvislosti zpracovávat za účelem předání příslušné bance, která provozuje
            platební bránu, za účelem Vaší dostatečné identifikace a splnění všech povinností, které
            se na nás vztahují. Právním základem je plnění smluvní povinnosti mezi námi a Vámi a
            zároveň plnění zákonných povinností, které se na nás v této souvislosti vztahují,
            případně zákonných povinností, které se vztahují na banku provozující platební bránu,
            které pomáháme tyto údaje získat.
          </p>
        </li>
        <li>
          <h3>Informace získané při využívání naší Aplikace</h3>
          <p>
            Tyto údaje využíváme, abychom lépe pochopili, jak využíváte Aplikaci, případně, abychom
            vyřešili různé servisní a jiné úkony, které mohou souviset s fungováním Aplikace. Osobní
            údaje jsou zpracovány na základě našeho oprávněného zájmu, který spočívá v zajištění
            funkčnosti Aplikace a zdokonalování našich Služeb.
          </p>
          <p>
            Osobní údaje pro zřízení a využívání uživatelského účtu budeme zpracovávat na základě
            plnění smluvní povinnosti a na základě našeho oprávněného zájmu, který spočívá v
            zajištění fungování uživatelského účtu a poskytování speciálních akcí spojených s
            uživatelským účtem.
          </p>
        </li>
        <li>
          <h3>
            V případě, že nás kontaktujete prostřednictvím kontaktního formuláře, nebo zadáte své
            telefonní číslo a nechcete odebírat naše Služby.
          </h3>
          <p>
            Osobní údaje zpracováváme pro účely vyřízení Vaší žádosti nebo zodpovězení Vašeho
            dotazu, a to na základě našeho oprávněného zájmu, který spočívá v zajištění komunikace
            mezi vámi a námi.
          </p>
        </li>
        <li>
          <h3>
            V souvislosti s Aplikací při plnění zákonných povinností dle DAC7
          </h3>
          <p>
            Osobní údaje v souvislosti s plněním povinností stanovených v DAC7, tedy pro účely evidence, 
            posouzení a následného oznámení informací příslušným správním orgánům, a to za účelem splnění 
            naší povinnosti jako daňového subjektu. Součástí zpracování tak bude předávání osobních údajů 
            správci daně. Osobní údaje budou zpracovávány za předpokladu, že jste tzv. oznamovaný prodejce, 
            tedy splníte limity stanovené DAC7. 
          </p>
        </li>
      </ul>
      <hr />
      <h2>4. Po jakou dobu budeme Vaše osobní údaje zpracovávat?</h2>
      <p>
        Vaše osobní údaje budeme zpracovávat pouze po dobu nezbytně nutnou k naplnění výše uvedených
        účelů, pro které byly shromážděny – poskytování Služeb, zajištění fungování Aplikace a
        splnění právních závazků, které z těchto účelů vyplývají. Po uplynutí účelu, pro které byly
        osobní údaje shromážděny a neexistenci jiných účelů osobní údaje vymažeme nebo
        anonymizujeme.
      </p>
      <p>
        V souvislosti s platební bránou budeme osobní údaje uchovávat po dobu, po kterou nám to
        stanovují právní předpisy. Prosím vezměte na vědomí, že banka, která zajišťuje platební
        bránu, bude tyto informace ukládat po dobu 10 let.
      </p>
      <p>
        V souvislosti s plněním povinností dle § 14zze DAC7 budeme uchovávat Vaše osobní údaje 
        po dobu 10 let od konce oznamovacího období (kalendářní rok). 
      </p>
      <h2>5. Kdo má k Vašim osobním údajům přístup?</h2>
      <p>Vaše osobní údaje předáváme:</p>
      <ul>
        <li>restauracím v případě, že máte konkrétní dotaz či žádost na tuto restauraci;</li>
        <li>obchodním zástupcům, kteří nám zajišťují uzavírání smluv;</li>
        <li>
          v případě, že chcete využívat platební bránu - bance, která zajišťuje poskytnutí platební
          brány, a to konkrétně společnost Československá obchodní banka, a. s., se sídlem Praha 5,
          Radlická 333/150, PSČ 15057, IČO: 0000135
        </li>
        <li>
          našim dodavatelům IT, hostingových a marketingových služeb, poskytovatelům statistických,
          databázových a jiných softwarových nástrojů, a případně jiným subjektům, které nám
          poskytují své služby.
        </li>
      </ul>
      <p>
        Na základě právních předpisů můžeme být za určitých předpokladů povinni Vaše osobní údaje
        sdílet bez Vašeho souhlasu se třetími osobami (například s orgány činnými v trestním řízení
        atp.). V souvislosti s plněním povinností dle DAC7 jsou Vaše osobní údaje v tomto rozsahu sdíleny 
        s příslušným finančním úřadem. Jedná se o naši zákonnou povinnost.{" "}
      </p>
      <hr />

      <h2>6. Jaká jsou Vaše práva?</h2>
      <p>Ve vztahu k námi prováděnému zpracování Vašich osobních údajů máte následující práva:</p>
      <ul>
        <li>
          <h3>Právo na přístup.</h3>
          <p>
            Máte právo získat potvrzení o tom, zda a jaké Vaše osobní údaje zpracováváme včetně
            práva na kopie osobních údajů, které zpracováváme. Berte prosím v úvahu, že nebude možné
            zpřístupnění takových informací, které by ohrozily naše obchodní tajemství nebo práva
            třetích osob.
          </p>
        </li>
        <li>
          <h3>Právo na opravu a doplnění.</h3>
          <p>
            Pokud zjistíte, že jsou Vaše osobní údaje, které zpracováváme, nepřesné nebo neúplné,
            máte právo nás požádat o jejich opravu a aktualizaci.
          </p>
        </li>
        <li>
          <h3>Právo na výmaz.</h3>
          <p>
            V některých případech nás máte právo oslovit s žádostí o vymazání Vašich osobních údajů,
            které zpracováváme. Berte prosím v úvahu, že výmaz osobních údajů není možné provést
            vždy, zejména ne tehdy, pokud zpracování probíhá pro legitimní účel a v souladu s
            předpisy na ochranu osobních údajů.
          </p>
        </li>
        <li>
          <h3>Právo na omezení zpracování.</h3>
          <p>
            V některých případech, např. v případě, kdy zpochybňujete přesnost, zákonnost nebo naši
            potřebu zpracovávat Vaše osobní údaje, máte právo nás požádat o omezení zpracování
            Vašich osobních údajů. Pokud Vaší žádosti vyhovíme, omezíme zpracování osobních údajů na
            nezbytné minimum (a údaje zpravidla nebudeme dále aktivně zpracovávat). V našich
            systémech zaznamenáme, že se na dané údaje vztahuje omezení. Pokud dojde ke zrušení
            omezení a my budeme mít v úmyslu ve zpracovávání Vašich osobních údajů pokračovat,
            budeme Vás o tom předem informovat.
          </p>
        </li>
        <li>
          <h3>Právo na přenositelnost.</h3>
          <p>
            Máte právo požadovat, abychom Vaše osobní údaje, které jste nám poskytli a které
            zpracováváme, předali (a to v běžně používaném a strojově čitelném formátu) třetí straně
            (jinému správci údajů), kterého uvedete ve Vaší žádosti. Berte prosím v úvahu, že toto
            své právo můžete uplatnit, pouze pokud zpracováváme Vaše osobní údaje na základě Vašeho
            souhlasu nebo pro účely plnění smlouvy a zároveň je zpracování automatizované.
          </p>
        </li>
        <li>
          <h3>Právo vznést námitku.</h3>
          <p>
            Můžete vznést námitku proti zpracování Vašich osobních údajů, které zpracováváme pro
            účely přímého marketingu nebo z důvodu oprávněného zájmu. Pokud vznesete námitku proti
            zpracování pro účely přímého marketingu, nebudou již Vaše osobní údaje pro tyto účely
            zpracovávány. V případě námitky proti zpracování, založeného na dalších důvodech, bude
            tato námitka vyhodnocena a následně Vám sdělíme, zda jsme jí vyhověli a Vaše údaje
            nebudeme nadále zpracovávat, nebo že námitka nebyla důvodná a zpracování bude
            pokračovat. Každopádně po dobu, než bude námitka vyřešena, bude zpracování omezeno.
          </p>
        </li>
        <li>
          <h3>Právo podat stížnost u dozorového orgánu.</h3>
          <p>
            Pokud se domníváte, že došlo k porušení povinností stanovených právními předpisy na
            ochranu osobních údajů, máte právo podat stížnost u Úřadu pro ochranu osobních údajů
            nebo u jiného úřadu členského státu Evropské unie, který je pověřen dozorem nad
            dodržováním povinností v oblasti ochrany osobních údajů. Záleží nám na Vás, budeme proto
            velmi rádi, pokud se před případným podáním stížnosti obrátíte přímo na nás a dáte nám
            tak příležitost vše vyřešit přímo s Vámi.
          </p>
          <p>
            Všechna svá práva můžete uplatnit na e-mailové adrese{" "}
            <a href="mailto:info@speedlo.cz">info&#64;speedlo.cz</a>. V případě, že využíváte služeb
            platební brány a chtěli byste uplatnit právo na přístup, omezení či výmaz Vašich
            osobních údajů získaných pro účely zpřístupnění platební brány, prosím obraťte se s
            touto žádostí přímo na nás a my společně s poskytovatelem platební brány tuto žádost
            vyřídíme.
          </p>
          <p>Tyto zásady jsou účinné od 1. 1. 2024.</p>
        </li>
      </ul>
    </Fragment>
  )
}

export default PrivacyPolicyCZ
